import { cn } from '../../../../utils';
import Link from '../../Link';

import type { HTMLAttributes } from 'react';

type Props = {
  count?: number;
  href?: string;
} & HTMLAttributes<HTMLElement>;

const IconButton = ({ count, href, children, className, ...props }: Props) => {
  const displayCount = typeof count === 'number' && count > 0;

  if (href) {
    return (
      <Link {...props} href={href} className={cn(style.button._, style.button.hover, className)}>
        {children}
        {displayCount && <span className={style.count}>{count}</span>}
      </Link>
    );
  }

  return (
    <button {...props} className={cn(style.button._, style.button.hover, className)}>
      {children}
      {displayCount && <span className={style.count}>{count}</span>}
    </button>
  );
};

export default IconButton;

const style = {
  button: {
    _: 'relative flex h-full w-12 items-center justify-center rounded-sm',
    hover: 'lg:hover:bg-background-primary-faded',
  },
  count:
    'absolute bottom-3 lg:bottom-1 right-2 flex h-4 w-4 items-center justify-center rounded-full bg-background-primary text-[10px] font-bold leading-none text-white',
};
