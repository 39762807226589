import { useShallow } from 'zustand/react/shallow';

import { useLayoutStore } from '../../store';
import { cn } from '../../utils';
import IconButton from '../common/ui/buttons/IconButton';

import type { HTMLAttributes } from 'react';

type MobileNavButtonProps = HTMLAttributes<HTMLButtonElement>;
type BurgerLinesStyle = 'top' | 'mid' | 'bottom';

const burgerLinesStyles: BurgerLinesStyle[] = ['top', 'mid', 'bottom'];

export const MobileNavButton = ({ className }: MobileNavButtonProps) => {
  const { toggleMobileNav, isMobileNavOpen } = useLayoutStore(
    useShallow((state) => ({
      toggleMobileNav: state.toggleMobileNav,
      isMobileNavOpen: state.isMobileNavOpen,
    })),
  );

  return (
    <IconButton
      className={cn(styles.burger.button._, className)}
      aria-label={`${isMobileNavOpen ? 'Close' : 'Open'} navigation menu`}
      aria-expanded={isMobileNavOpen}
      aria-controls="mega-nav-mobile"
      onClick={toggleMobileNav}
    >
      {burgerLinesStyles.map((line) => {
        const lineStyles = styles.burger.lines[line];
        return (
          <span
            key={line}
            className={cn(
              styles.burger.lines._,
              lineStyles._,
              isMobileNavOpen && [styles.burger.lines.active, lineStyles.active],
            )}
          />
        );
      })}
    </IconButton>
  );
};
const styles = {
  burger: {
    button: {
      _: 'flex-col gap-[3px] rounded-none lg:hidden lg:rounded-sm lg:border-y-0 focus:outline-none',
    },
    lines: {
      _: 'h-0.5 bg-current w-4 rounded transition-[transform,background-color] duration-250 ease-out',
      active: 'bg-grey-700 dark:bg-persistent-text-light',
      top: {
        _: 'rotate-0 translate origin-top-left',
        active: 'rotate-45 translate-x-[3px] -translate-y-px',
      },
      mid: {
        _: 'opacity-100',
        active: 'opacity-0',
      },
      bottom: {
        _: 'rotate-0 translate origin-bottom-left',
        active: '-rotate-45 translate-x-[3px] translate-y-0',
      },
    },
  },
};
