import type { CollectionChoiceInfo, LocationsInfo } from '../types';

import { ErrorCodes, handleRichError } from '../utils/rich-error';
import { formatMediaInfo, formatReviewsInfo, formatValidity } from './formatPDPInfo';

// TODO: rebase after LP-5652
export const MEDIA_IMAGE_URL_PUBLIC =
  process.env.NEXT_PUBLIC_BACKEND_API_MEDIA_CDN_BASE_URL ??
  'https://images.virginexperiencedays.co.uk/images/product/main';

const COLLECTION_CHOICE_INFO_REQUIRED_FIELDS = [
  'id',
  'slug',
  'title',
  'sku',
  'status',
  'description',
  'info',
] as const;

/**
 * Formats the media information from the response object.
 * @param response - The response object containing Collection Choice information.
 * @returns The formatted Collection Choice information or null.
 */
// biome-ignore lint/suspicious/noExplicitAny: TODO LP-7094: use the endpoint's schema so we have unknown -> CollectionChoiceInfo
export function formatCollectionChoiceInfo(response: any): CollectionChoiceInfo | null {
  try {
    if (typeof response !== 'object' || response === null) {
      throw new Error('Unable to format CollectionChoiceInfo. Invalid response format');
    }

    for (const field of COLLECTION_CHOICE_INFO_REQUIRED_FIELDS) {
      if (!response[field]) {
        throw new Error(`Missing required field: ${field}`);
      }
    }
    const id = Number(response.id);
    if (Number.isNaN(id)) {
      throw new Error('Invalid id: must be a number');
    }

    const collectionChoiceInfo: CollectionChoiceInfo = {
      id: id,
      slug: response.slug,
      title: response.title,
      sku: response.sku,
      status: response.status,
      description: response.description,
      info: response.info,
      media: formatMediaInfo(response, MEDIA_IMAGE_URL_PUBLIC),
      reviews: formatReviewsInfo(response),
      validity: formatValidity(response),
      locations: formatLocations(response),
    };

    return collectionChoiceInfo;
  } catch (error) {
    console.error(handleRichError(ErrorCodes.INVALID_RESPONSE_FORMAT, error));

    return null;
  }
}

/**
 * Formats the locations information from the response object.
 * @param response - The response object containing the locations information.
 * @returns An Array of objects with the formatted location information.
 */
// biome-ignore lint/suspicious/noExplicitAny: TODO LP-7094: use the endpoint's schema so we have unknown -> CollectionChoiceInfo
export function formatLocations(response: any): LocationsInfo[] {
  if (
    typeof response.locations === 'object' &&
    response.locations !== null &&
    Array.isArray(response.locations)
  ) {
    // biome-ignore lint/suspicious/noExplicitAny: TODO LP-7094: use the endpoint's schema so we have unknown -> CollectionChoiceInfo
    return response.locations.filter((location: any): location is LocationsInfo => {
      const lat = typeof location.lat === 'string' ? Number.parseFloat(location.lat) : location.lat;
      const lng = typeof location.lng === 'string' ? Number.parseFloat(location.lng) : location.lng;

      const isValid =
        typeof location === 'object' &&
        location !== null &&
        typeof location.name === 'string' &&
        !Number.isNaN(lat) &&
        !Number.isNaN(lng) &&
        lat >= -90 &&
        lat <= 90 &&
        lng >= -180 &&
        lng <= 180;

      if (!isValid) {
        console.warn('Invalid location object found: ', location);
      }
      return isValid;
    });
  }

  return [];
}
