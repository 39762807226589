import { create } from 'zustand';
import { useShallow } from 'zustand/react/shallow';
import { HeaderState } from '../enums/HeaderState';

export type TrackingStore = {
  headerState: HeaderState;
  setHeaderState: (headerState: HeaderState) => void;
};

export const useTrackingStore = create<TrackingStore>()((set) => ({
  headerState: HeaderState.Exposed,
  setHeaderState: (headerState: HeaderState) => set(() => ({ headerState })),
}));
/**
 * A hook that returns the entire tracking store with shallow comparison
 * to prevent unnecessary re-renders.
 */
export const useShallowTrackingStore = (): TrackingStore => {
  return useTrackingStore(useShallow((state) => state));
};
