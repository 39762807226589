import { debounce } from 'lodash';
import { inView } from 'motion';
import { type CSSProperties, useEffect, useRef, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { useHeaderContext } from '../../context/HeaderContext';
import { ThemeProvider } from '../../context/ThemeContext';
import { useScrollLock } from '../../hooks';
import { useIsMobileView } from '../../hooks/useIsMobileView';
import { useLayoutStore, useShallowTrackingStore } from '../../store';
import { cn, logoToDataLayer, searchOpenToDataLayer } from '../../utils';

import PromoBanners from '../MegaNav/Desktop/PromoBanners';
import { BlurScreen } from '../common/BlurScreen';

import { HeaderImmersiveSearch } from './HeaderImmersiveSearch';
import { HeaderMenu } from './HeaderMenu';
import { HeaderTop } from './HeaderTop';
import SearchOverlay from './SearchOverlay';
import { HEADER_HEIGHT, HEADER_HEIGHT_CSS_VARS } from './styles';

import { HeaderState } from '../../enums/HeaderState';
import type { HeaderProps } from '../../types';

const Header = ({ hideOn = [], immersive, navigation, noSticky, theme }: HeaderProps) => {
  const {
    NextRouter,
    siteLogoDefault,
    siteLogoWhite,
    searchState,
    handleUpdateSearchState,
    trackingPageType,
  } = useHeaderContext();

  const { isMobileNavOpen, setIsMobileNavOpen } = useLayoutStore(
    useShallow((state) => ({
      isMobileNavOpen: state.isMobileNavOpen,
      setIsMobileNavOpen: state.setIsMobileNavOpen,
    })),
  ); // MOBILE nav state
  const { headerState, setHeaderState } = useShallowTrackingStore();

  const refInViewCleanUp = useRef<VoidFunction>();

  const [navExpanded, setNavExpanded] = useState(false); // DESKTOP nav state
  // when route is changing, we want to disable some of header transitions to avoid flickering - logo, search
  const [isRouteChanging, setIsRouteChanging] = useState(false);
  const [isTop, setIsTop] = useState(true);
  const [isTopImmersive, setIsTopImmersive] = useState(true);
  const [isVisible, setIsVisible] = useState(true);

  const isMobileView = useIsMobileView();

  const { isImmersive, isImmersiveHidden, isLarge, isSticky, hasPageBg } = getLayoutState({
    immersive,
    isMobileNavOpen,
    isTop,
    isTopImmersive,
    noSticky,
  });

  const isMobileSearchOpen = isMobileView && searchState.searchOverlay;
  const showMobileBlur = isMobileNavOpen || searchState.searchOverlay;
  const showDesktopBlur = navExpanded || searchState.searchOverlay;
  const myVoucherLink = navigation?.links?.myVoucher;

  // desktop navigation handler
  const handleNavExpanded = (expanded: boolean) => {
    setNavExpanded(expanded);
  };

  // search component handler
  const handleToggleSearchOpen = () => {
    searchOpenToDataLayer(headerState);
    handleUpdateSearchState({ searchOverlay: true });
  };

  // close mobile navigation and search overlay when clicking outside
  const handleClickOutside = () => {
    setIsMobileNavOpen(false);
    handleUpdateSearchState({ searchOverlay: false });
  };

  // close navigations and search overlay e.g. clicking logo, popular products, recently viewed
  const handleCloseNavAndSearch = () => {
    setNavExpanded(false);
    isMobileNavOpen && setIsMobileNavOpen(false);
    handleUpdateSearchState({ searchOverlay: false });
  };

  const onLogoClick = () => {
    logoToDataLayer();
    handleCloseNavAndSearch();
  };

  useScrollLock(isMobileNavOpen || isMobileSearchOpen);

  useEffect(() => {
    const handleScroll = debounce(() => {
      const { scrollY } = window;

      setIsTop(scrollY <= 0);
      setIsTopImmersive(scrollY <= HEADER_HEIGHT.mobile.large);
    }, 16);

    if (!isMobileView) {
      window.removeEventListener('scroll', handleScroll);
      setIsTop(true);
      setIsTopImmersive(true);
      return;
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      handleScroll.cancel();
    };
  }, [isMobileView]);

  useEffect(() => {
    if (!NextRouter) return;

    const onRouteChangeStart = () => {
      setIsRouteChanging(true);
    };

    const onRouteChangeComplete = () => {
      if (isMobileView) {
        const { scrollY } = window;

        setIsTop(scrollY <= 0);
        setIsTopImmersive(scrollY <= HEADER_HEIGHT.mobile.large);
      } else {
        setIsTop(true);
        setIsTopImmersive(true);
      }

      setTimeout(() => setIsRouteChanging(false), 0);
    };

    const removeListeners = () => {
      NextRouter.events.off('routeChangeStart', onRouteChangeStart);
      NextRouter.events.off('routeChangeComplete', onRouteChangeComplete);
    };

    removeListeners();

    NextRouter.events.on('routeChangeStart', onRouteChangeStart);
    NextRouter.events.on('routeChangeComplete', onRouteChangeComplete);

    return () => {
      removeListeners();
    };
  }, [isMobileView, NextRouter]);

  // close mobile navigation when viewport width changes to desktop and vice versa
  useEffect(() => {
    if (isMobileView) setNavExpanded(false);
    else setIsMobileNavOpen(false);
  }, [isMobileView, setIsMobileNavOpen]);

  useEffect(() => {
    if (isMobileNavOpen) return;

    if (!isTop) {
      setHeaderState(HeaderState.Sticky);
    } else if (isTopImmersive) {
      setHeaderState(trackingPageType === 'Home' ? HeaderState.Exposed : HeaderState.Slim);
    }
  }, [trackingPageType, setHeaderState, isMobileNavOpen, isTop, isTopImmersive]);

  useEffect(() => {
    setIsVisible(true);
    refInViewCleanUp.current?.();

    if (!isMobileView) return;

    const els = hideOn
      .filter(({ pages }) => {
        const pagesArr = Array.isArray(pages) ? pages : [pages];

        return !pages || pagesArr.includes(trackingPageType);
      })
      .map(({ el }) => document.querySelector(el))
      .filter((el) => el !== null);

    refInViewCleanUp.current = inView(
      els,
      () => {
        setIsVisible(false);

        return () => {
          setIsVisible(true);
        };
      },
      { margin: '0px 0px -95% 0px' },
    );

    return () => {
      refInViewCleanUp.current?.();
    };
  }, [hideOn, isMobileView, trackingPageType]);

  return (
    <ThemeProvider theme={theme} immersive={immersive}>
      <header
        className={cn(
          style.header._,
          immersive && theme !== 'dark' && 'border-background-neutral-strong',
          immersive && style.header.immersive,
        )}
        style={
          {
            ...HEADER_HEIGHT_CSS_VARS,
            '--header-transition-timing': isRouteChanging ? '0' : '0.15s',
          } as CSSProperties
        }
      >
        <PromoBanners promoBanners={navigation?.promoBanner} />

        <div
          className={cn(
            style.container._,
            isSticky && style.container.sticky,
            hasPageBg && style.container.hasBg,
            isImmersive && !isMobileNavOpen && style.container.immersive,
            isMobileNavOpen && style.container.noGradient,
            !isVisible && !isMobileNavOpen && style.container.isHidden,
            noSticky && style.container.noSticky,
          )}
        >
          <div className={cn(style.inner._)}>
            <HeaderTop
              isImmersive={isImmersive}
              isImmersiveHidden={isImmersiveHidden}
              isSticky={isSticky}
              links={navigation?.links}
              myVoucherLink={myVoucherLink}
              onLogoClick={onLogoClick}
              onSearchClick={handleToggleSearchOpen}
            />

            <HeaderImmersiveSearch
              isImmersive={isImmersive}
              isLarge={isLarge}
              onSearchClick={handleToggleSearchOpen}
            />

            {!!navigation?.navItems?.length && (
              <HeaderMenu
                className={cn('lg:pt-1')}
                handleNavExpanded={handleNavExpanded}
                mode="desktop"
                navExpanded={navExpanded}
                navigation={navigation}
              />
            )}

            <div className={style.divider._} />
          </div>
        </div>

        {!!navigation?.navItems?.length && (
          <HeaderMenu
            className="lg:pt-1"
            handleNavExpanded={handleNavExpanded}
            mode="mobile"
            navExpanded={navExpanded}
            navigation={navigation}
          />
        )}

        {searchState.searchOverlay && (
          <SearchOverlay
            logoSrc={{ red: siteLogoDefault, white: siteLogoWhite }}
            handleLinkClicks={handleCloseNavAndSearch}
          />
        )}

        <BlurScreen isVisible={showMobileBlur || showDesktopBlur} onClick={handleClickOutside} />
      </header>
    </ThemeProvider>
  );
};

export default Header;

const style = {
  header: {
    _: [
      'relative isolate flex flex-col w-full h-[--header-h-mobile-default] z-40 [&_*]:font-inter',
      'lg:h-auto',
    ],
    immersive: 'h-0 lg:h-0',
  },
  container: {
    _: [
      'fixed top-0 left-0 h-[--header-h-mobile-default] w-full z-50 transition-[opacity,visibility] ease-out duration-[0.15s,0s] delay-[0.15s,0s]',
      'before:absolute before:top-0 before:left-1/2 before:w-full before:h-full before:bg-background-page before:z-[-1] before:opacity-0 before:transition-opacity before:ease-out before:duration-150 before:transform before:-translate-x-1/2 before:pointer-events-none',
      'after:absolute after:top-0 after:left-1/2 after:w-full after:h-[--header-h-mobile-large] after:bg-transparent after:bg-gradient-to-t after:from-[rgba(46,46,46,0)] after:to-[rgba(46,46,46,0.4)] after:opacity-0 after:z-[-1] after:pointer-events-none after:transform after:-translate-x-1/2',
      'lg:relative lg:h-auto lg:before:opacity-100',
    ],
    immersive: [
      'absolute before:opacity-0 after:opacity-100',
      'lg:border-persistent-border-translucent-light lg:before:opacity-0',
    ],
    noGradient: 'after:opacity-0',
    noSticky: 'absolute lg:relative',
    sticky: 'shadow-md z-50 lg:shadow-none before:opacity-100',
    hasBg: 'before:opacity-100',
    isHidden:
      'opacity-0 invisible delay-[0s,0.15s] pointer-events-none lg:opacity-100 lg:visible lg:pointer-events-auto',
  },
  inner: {
    _: [
      'flex flex-col items-center w-full max-w-[1440px] h-full py-1 px-4 mx-auto',
      'lg:gap-x-2 lg:gap-y-4 lg:px-[40px] lg:pt-6 lg:pb-0 xl:px-[80px]',
    ],
  },
  divider: {
    _: 'hidden relative w-full transform translate-y-[1px] -z-[1] lg:block lg:h-[1px] lg:w-full lg:bg-persistent-border-translucent-light',
  },
};

type LayoutStateProps = {
  immersive?: boolean;
  isMobileNavOpen: boolean;
  isTop: boolean;
  isTopImmersive: boolean;
  noSticky?: boolean;
};

/**
 * Calculates header layout state based on current navigation conditions
 *
 * @param {object} params - Layout state parameters
 * @param {boolean} params.immersive - Whether the header is in immersive mode
 * @param {boolean} params.isMobileNavOpen - Whether the mobile navigation is open
 * @param {boolean} params.isTop - Whether the viewport is at the top of the page
 * @param {boolean} params.isTopImmersive - Whether immersive mode should be shown at the top
 * @param {boolean} params.noSticky - Whether sticky behavior should be disabled
 *
 * @returns {object} Layout state flags
 * @returns {boolean} returns.isImmersive - True when in immersive mode and at immersive top position
 * @returns {boolean} returns.isImmersiveHidden - True when in immersive mode but not at immersive top position
 * @returns {boolean} returns.isLarge - True when mobile nav is open or in immersive mode
 * @returns {boolean} returns.isSticky - True when header should be sticky and mobile nav is closed
 * @returns {boolean} returns.hasPageBg - True when header should display with page background
 */
const getLayoutState = ({
  immersive,
  isMobileNavOpen,
  isTop,
  isTopImmersive,
  noSticky,
}: LayoutStateProps) => {
  const isImmersive = immersive && isTopImmersive;
  const isImmersiveHidden = immersive && !isTopImmersive;
  const isLarge = isMobileNavOpen || isImmersive;
  const isStickyNonImmersive = !isTop && !immersive; // unused outside of this function
  const isStickyPosition = !noSticky && (isStickyNonImmersive || isImmersiveHidden); // unused outside of this function
  const isSticky = !isMobileNavOpen && isStickyPosition;
  const hasPageBg =
    (isMobileNavOpen && isStickyPosition) || (!isMobileNavOpen && !immersive && isTop);

  return { isImmersive, isImmersiveHidden, isLarge, isSticky, hasPageBg };
};
