import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

const BREAKPOINT_DESKTOP = 768;
const DEBOUNCE_DELAY = 200;

export const useWindowWidth = () => {
  const [width, setWidth] = useState<number>();

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    const debouncedHandleResize = debounce(handleResize, DEBOUNCE_DELAY);

    window.addEventListener('resize', debouncedHandleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
      debouncedHandleResize.cancel();
    };
  }, []);

  // detect by window width
  const isDesktop = () => {
    return width >= BREAKPOINT_DESKTOP;
  };

  // detect by window width AND touch events so we know if we are on a mobile or mobile-like device
  const isDesktopMouse = () => {
    return isDesktop() && window !== undefined && window?.ontouchstart !== undefined;
  };

  return { width, isDesktop, isDesktopMouse };
};
