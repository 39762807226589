/**
+ * Defines the header heights for different device sizes
+ */
export const HEADER_HEIGHT = {
  mobile: {
    default: 64,
    large: 120,
  },
};

/**
+ * Generates CSS variables from HEADER_HEIGHT object
+ * in the format --header-h-{deviceSize}-{sizeType}
+ */
export const HEADER_HEIGHT_CSS_VARS = Object.entries(HEADER_HEIGHT).reduce(
  (acc: { [key: string]: string }, [k, v]) => {
    for (const [kNested, vNested] of Object.entries(v)) {
      const cssVarName = `--header-h-${k}-${kNested}`.toLowerCase();
      acc[cssVarName] = `${vNested}px`;
    }
    return acc;
  },
  {},
);
