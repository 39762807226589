import type { HTMLAttributes } from 'react';
import { useTheme } from '../../context/ThemeContext';
import { AriaLabel } from '../../enums';
import { useIsMobileView } from '../../hooks/useIsMobileView';
import { useTrackingStore } from '../../store';
import type { HeaderProps } from '../../types';
import { basketToDataLayer, cn } from '../../utils';
import RenderIcon from '../common/icons/RenderIcon';
import IconButton from '../common/ui/buttons/IconButton';
import SearchBarButton from '../common/ui/buttons/SearchBarButton';
import { HeaderVoucherLink } from './HeaderVoucherLink';
import { MobileNavButton } from './MobileNavButton';

export type HeaderButtonGroupProps = {
  basketCount: number;
  hasSearchButton?: boolean;
  hasWideSearch?: boolean;
  hideBasketCount?: boolean;
  isImmersive?: boolean;
  links: HeaderProps['navigation']['links'];
  myVoucherLink?: string | null;
  onSearchClick?: () => void;
  saveForLaterCount: number;
};

type Props = HeaderButtonGroupProps & HTMLAttributes<HTMLDivElement>;

const HeaderButtonGroup = ({
  className,
  basketCount,
  hasSearchButton,
  hasWideSearch,
  hideBasketCount,
  isImmersive,
  links,
  myVoucherLink,
  onSearchClick,
  saveForLaterCount,
  ...props
}: Props) => {
  const { immersive } = useTheme();
  const isMobileView = useIsMobileView();

  const headerState = useTrackingStore((state) => state.headerState);

  const iconProps = {
    className: cn(
      'w-6 h-6 lg:w-[18px] lg:h-[18px]',
      isImmersive ? '[&_path]:stroke-[--persistent-white]' : '[&_path]:stroke-[--text-neutral]',
    ),
  };

  return (
    <div className={cn(style.container, className)} {...props}>
      <div className={style.searchWrapper}>
        <SearchBarButton
          className={cn(style.search._, hasWideSearch && style.search.isWide)}
          isDark={!isMobileView && immersive}
          placeholder="Search experiences..."
          onClick={onSearchClick}
        />

        <IconButton
          aria-label={AriaLabel.Search}
          className={cn(
            style.icon._,
            style.icon.search._,
            !hasSearchButton && style.icon.search.isHidden,
            isImmersive && style.button.immersive,
          )}
          onClick={onSearchClick}
        >
          <RenderIcon name="search" {...iconProps} />
        </IconButton>
      </div>

      <HeaderVoucherLink href={myVoucherLink} isLight={isImmersive} />

      {!!links?.saveForLater && (
        <IconButton
          aria-label={AriaLabel.LinkToSaved}
          count={saveForLaterCount}
          href={links.saveForLater}
          className={cn(style.icon._, isImmersive && style.button.immersive)}
        >
          <RenderIcon name="heart" {...iconProps} />
        </IconButton>
      )}

      {!!links?.basket && (
        <IconButton
          aria-label={AriaLabel.LinkToBasket}
          onClick={() => basketToDataLayer(headerState)}
          // enforce 0 count to not display item count when hideBasketCount is true (e.g. C# basket page)
          count={hideBasketCount ? 0 : basketCount}
          href={links.basket}
          className={cn(style.icon._, isImmersive && style.button.immersive)}
        >
          <RenderIcon name="cart" {...iconProps} />
        </IconButton>
      )}

      <MobileNavButton className={cn(style.nav._, isImmersive && style.nav.immersive)} />
    </div>
  );
};

export default HeaderButtonGroup;

const style = {
  container: 'flex items-center w-full h-full lg:gap-x-2 lg:justify-end',
  searchWrapper: 'relative flex flex-grow items-center h-full lg:mx-4',
  search: {
    _: [
      'invisible w-full max-w-[1440px] opacity-0 transition-[opacity,visibility] delay-[0s,var(--header-transition-timing)] duration-[var(--header-transition-timing),0s] ease-out',
      'lg:visible lg:opacity-100',
    ],
    isWide: 'opacity-100 visible delay-[0s,0s]',
  },
  button: {
    immersive: 'lg:hover:bg-persistent-background-primary-faded',
  },
  icon: {
    _: 'lg:h-10 [&_path]:hover:stroke-persistent-text-dark',
    search: {
      _: [
        'absolute top-0 right-0 [&_path]:stroke-[1.5] transition-[opacity,visibility] duration-[var(--header-transition-timing),0s] ease-out',
        'lg:hidden',
      ],
      isHidden: 'invisible opacity-0 delay-[0s,var(--header-transition-timing)]',
    },
  },
  nav: {
    _: 'px-3 text-[--text-natural]',
    immersive: 'text-[--persistent-white]',
  },
};
