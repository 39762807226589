import { cn, navLinkToDataLayer } from '../../../../utils';
import RenderIcon from '../../../common/icons/RenderIcon';
import Link from '../../../common/Link';
import { MIN_LIST_WIDTH } from '../../../common/ui/ListContainer';
import PromoRibbon from '../../../common/ui/PromoRibbon';

import { useMenuContext } from './MenuContext';

import type { DeviceConfigType, RibbonType } from '../../../../types';
import type { NormalizedNavItem } from '../../../../utils/normalizeNavigationItem';
import type { HTMLAttributes, ReactNode } from 'react';

type Props = {
  parentNameList: string[];
  displayIcon?: boolean;
  bold?: boolean;
  forceHoverStyle?: boolean;
  shopAllLink?: boolean;
  inOverflowMenu?: boolean;
  desktop?: Omit<DeviceConfigType, 'cta'>;
} & NormalizedNavItem &
  HTMLAttributes<HTMLElement | HTMLAnchorElement>;

const NestedMenuItem = ({
  name,
  nameSEO,
  icon,
  route,
  parentNameList = [],
  shopAll,
  shopAllLink,
  childItems,
  displayIcon,
  bold,
  forceHoverStyle,
  inOverflowMenu,
  desktop,
  className,
  ...props
}: Props) => {
  const { handleClose: handleForceCloseNav } = useMenuContext();
  const { ribbon } = desktop || {};
  const itemName = nameSEO ?? name;

  const hasChildren = Array.isArray(childItems) ? !!childItems.length : false;

  const handleClick = () => {
    navLinkToDataLayer({ path: parentNameList, value: itemName });
    handleForceCloseNav();
  };

  // renders an h3 if there are children and item is not in the overflow menu
  if (hasChildren && !inOverflowMenu) {
    return (
      <NestedMenuItemWrapper
        {...props}
        tabIndex={0}
        className={cn(
          style.item._,
          !!bold && 'font-medium',
          !!forceHoverStyle && style.item.selected,
          !!shopAllLink && style.item.shopAll,
          className
        )}
        href={route}
        onClick={handleClick}
      >
        <ItemContent
          displayIcon={displayIcon}
          icon={icon}
          forceHoverStyle={forceHoverStyle}
          showRightArrow={!inOverflowMenu}
          ribbon={ribbon}
          itemName={itemName}
        />
      </NestedMenuItemWrapper>
    );
  }

  // no children
  if (!hasChildren && route) {
    // renders h3 + anchor tag if the nav is a second level item (third level items will be just links)
    if (parentNameList.length === 2) {
      return (
        <NestedMenuItemWrapper
          {...props}
          href={route}
          onClick={handleClick}
          className={cn(
            style.item._,
            !!bold && 'font-medium',
            !!forceHoverStyle && style.item.selected,
            !!shopAllLink && style.item.shopAll,
            className
          )}
        >
          <ItemContent
            displayIcon={displayIcon}
            icon={icon}
            forceHoverStyle={forceHoverStyle}
            ribbon={ribbon}
            itemName={itemName}
          />
        </NestedMenuItemWrapper>
      );
    }

    // renders anchor tag if there are no children, or if the item is in the overflow menu
    return (
      <Link
        {...props}
        href={route}
        onClick={handleClick}
        className={cn(
          style.item._,
          !!bold && 'font-medium',
          !!forceHoverStyle && style.item.selected,
          !!shopAllLink && style.item.shopAll,
          className
        )}
      >
        <ItemContent
          displayIcon={displayIcon}
          icon={icon}
          forceHoverStyle={forceHoverStyle}
          ribbon={ribbon}
          itemName={itemName}
        />
      </Link>
    );
  }

  return null;
};

export default NestedMenuItem;

type ItemContentProps = {
  itemName: string;
  displayIcon?: Props['displayIcon'];
  forceHoverStyle?: Props['forceHoverStyle'];
  icon?: Props['icon'];
  showRightArrow?: boolean;
  ribbon?: { type: RibbonType['type']; text: string };
};

const ItemContent = ({
  displayIcon,
  icon,
  forceHoverStyle,
  showRightArrow,
  ribbon,
  itemName,
}: ItemContentProps) => (
  <>
    {displayIcon && !!icon && (
      <RenderIcon
        className={cn(forceHoverStyle && 'text-nav-item-text-hover dark:text-white')}
        width={18}
        height={18}
        name={icon}
        device="desktop"
        fill={forceHoverStyle ? 'currentColor' : undefined}
      />
    )}
    <span
      className={cn('flex-1 truncate whitespace-nowrap pr-2', forceHoverStyle && forceHoverStyle)}
    >
      {itemName}
    </span>
    {!!ribbon && (
      <PromoRibbon type={ribbon.type} device="desktop">
        {ribbon.text}
      </PromoRibbon>
    )}
    {showRightArrow && <RenderIcon name="chevron-right" device="desktop" />}
  </>
);

const style = {
  item: {
    _: `p-2 w-full flex items-center gap-2 text-sm rounded-md text-left text-neutral cursor-pointer ${MIN_LIST_WIDTH} hover:bg-nav-item-background-hover hover:text-nav-item-text-hover`,
    selected: 'bg-nav-item-background-hover text-nav-item-text-hover',
    shopAll: 'font-semibold underline hover:underline',
  },
};

type NestedMenuItemWrapperProps = {
  href?: string;
  children?: ReactNode;
  className?: string;
} & HTMLAttributes<HTMLHeadingElement>;

const NestedMenuItemWrapper = ({
  href,
  children,
  className,
  ...props
}: NestedMenuItemWrapperProps) => {
  if (href) {
    return (
      <Link href={href} className="contents">
        <h3 className={className} {...props}>
          {children}
        </h3>
      </Link>
    );
  }

  return (
    <h3 className={className} {...props}>
      {children}
    </h3>
  );
};
