import { useEffect, useState } from 'react';

import { HeaderState } from '../../enums/HeaderState';
import { useLayoutStore, useShallowTrackingStore } from '../../store';
import { cn, openMobileNavToDataLayer } from '../../utils';
import { MegaNavDesktop, MegaNavMobile } from '../MegaNav';
import MenuContextProvider from '../MegaNav/Desktop/Menu/MenuContext';

import type { HeaderProps } from '../../types';

type Props = {
  navigation: HeaderProps['navigation'];
  className?: HTMLElement['className'];
  mode: 'mobile' | 'desktop';
  navExpanded: boolean;
  handleNavExpanded: (expanded: boolean) => void;
};

export const HeaderMenu = ({
  navigation,
  className,
  mode,
  navExpanded,
  handleNavExpanded,
}: Props) => {
  const { navItems, featuredColumn, secondaryNavItems, links } = navigation;

  const isMobileNavOpen = useLayoutStore((state) => state.isMobileNavOpen);
  const { headerState, setHeaderState } = useShallowTrackingStore();

  // we set it to true only on 1st burger button click to avoid rendering mobile nav on load
  // it's never changed again as we only hide/show it via CSS afterwards
  const [isMobileNavVisible, setIsMobileNavVisible] = useState(false);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <adding headerState as dependency will retrigger openMobileNavToDataLayer>
  useEffect(() => {
    if (mode !== 'mobile' || !isMobileNavOpen) return;

    setIsMobileNavVisible(true);
    // opened - log to data layer
    openMobileNavToDataLayer(headerState);
    setHeaderState(HeaderState.Menu);
  }, [isMobileNavOpen, mode]);

  return mode === 'desktop' ? (
    <MenuContextProvider>
      {/* Desktop Nav - this will be primarly used by crawlers, so we need it to be rendered at all times and just hide in mobile via CSS */}
      <MegaNavDesktop
        className={cn('mx-auto hidden lg:block', className, 'lg:p-0')}
        navigation={navItems}
        featuredColumn={featuredColumn}
        secondaryNavigation={secondaryNavItems ?? []}
        navExpanded={navExpanded}
        handleNavExpanded={handleNavExpanded}
      />
    </MenuContextProvider>
  ) : (
    <>
      {/* Mobile Nav - to avoid confusion to crawlers detecting the desktop navs on load, we only render mobile nav on burger button click */}
      {isMobileNavVisible && (
        <MegaNavMobile links={links} navItems={navItems} secondaryNavItems={secondaryNavItems} />
      )}
    </>
  );
};
