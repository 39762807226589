import GiftFinder, {
  useGiftFinderContext,
} from '@virginexperiencedays/components-v2/src/drawers/GiftFinder';
import { Image } from '@virginexperiencedays/components-v2/src/layout/Image';
import { CookiesModal } from '@virginexperiencedays/components-v2/src/modals/Cookie';
import { LinkWrap } from '@virginexperiencedays/components-v2/src/navigation/LinkWrap';
import { useDyCustomEventListener } from '@virginexperiencedays/components-v2/src/pages/product/DyPromoBanners';
import useFeatureFlags from '@virginexperiencedays/feature-flags/src/useFlags';
import { useScrollLock } from '@virginexperiencedays/hooks';
import {
  type FeaturedColumnProps,
  Header,
  HeaderProvider,
  LocalStorage,
  hasNavigationArray,
  useSwrNavigationCache,
  useValidateProducts,
} from '@virginexperiencedays/slim-header/src';
import { Footer } from '@virginexperiencedays/slim-header/src/components/Footer/Footer';
import type { FooterProps } from '@virginexperiencedays/slim-header/src/types';
import { formatFooterTopProducts } from '@virginexperiencedays/slim-header/src/utils/footerTopProducts/formatFooterTopProducts';
import { formatFooterRecentlyViewed } from '@virginexperiencedays/slim-header/src/utils/formatFooterRecentlyViewed';
import { cn } from '@virginexperiencedays/tailwind';
import { useRouter } from 'next/compat/router';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useCallback, useEffect, useState } from 'react';
import type { LayoutProps } from '.';
import { TrackingEventName } from '../../libs/tracking/types';
import { useB2bQuery } from '../../utils/b2b/useB2bQuery';
import { setDomainCookie } from '../../utils/cookie';
import { CookiesModalV2 } from '../CookieModalV2';
import { FBPixel } from '../tracking/fb';
import { PAGE_TYPES } from '../tracking/gtm/consts';
import { addToDataLayer } from '../tracking/gtm/utils';
import { KlaviyoScript } from '../tracking/klaviyo';
import { useKlaviyoViewedPage } from '../tracking/klaviyo/hooks';
import { useReportCfSplitTest } from '../tracking/useReportCfSplitTest';
import { envVariables, navigationServiceUrl } from './envVariables';

// Discount modal
const DiscountModal = dynamic(() => import('../DiscountModal'), {
  ssr: false,
});

const dyCampaignSelector = process.env.NEXT_PUBLIC_GLOBAL_CAMPAIGN_SELECTOR;

const VariationLayout = ({
  children,
  preview = false,
  initialNavItems = [],
  topProducts,
  trackingPageType,
  isImmersive = false,
}: LayoutProps) => {
  const router = useRouter();
  const [isEnabled] = useFeatureFlags();
  // Cookie Banner And Consent State
  const displayCookieModalV1 = useDyCustomEventListener('dy:cookie-modal-v1-mount');
  const displayCookieModalV2 = useDyCustomEventListener('dy:cookie-modal-v2-mount');
  const homeLink = process?.env?.NEXT_PUBLIC_HOME_LINK;
  const cookieModalHref = `${homeLink}/cookies-are-our-friends`;

  const [showCookieConsent, setShowCookieConsent] = useState(false);
  const cookieConsent = 'cookie-consent';

  const setCookieConsent = useCallback(() => {
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem(cookieConsent, 'true');
      setShowCookieConsent(false);
    }
  }, []);

  // Navigation Service
  const { header, footer } = useSwrNavigationCache(navigationServiceUrl, initialNavItems);

  const { main: headerMain, sub: headerSub, promo } = header;
  const { main: footerMain, sub: footerSub } = footer;
  // header navigation
  const navItems = hasNavigationArray(headerMain) ? headerMain.navigation : [];
  const featuredColumn =
    (headerMain as { featuredColumn?: FeaturedColumnProps })?.featuredColumn || null;
  const secondaryNavItems = hasNavigationArray(headerSub) ? headerSub.navigation : [];
  const promoBanner = promo as Record<string, unknown>;
  const links = (headerSub as { links?: Record<string, string | null> })?.links || {};
  // footer navigation
  const footerNav = hasNavigationArray(footerMain) ? footerMain.navigation : [];
  const helpLinks = hasNavigationArray(footerSub) ? footerSub.navigation : [];

  // Gift Finder
  const {
    state: { active: giftFinderActive },
  } = useGiftFinderContext();
  useScrollLock(giftFinderActive);

  useReportCfSplitTest({
    cookieKey: '_ved_cf_recorded',
    dyCampaignSelector,
    callback: addToDataLayer,
  });

  // Products
  const { products, loading, setStorageItem, setProducts } = useValidateProducts(
    LocalStorage.RecentlyViewedProducts,
    envVariables.productApiUrl,
    envVariables.productApiKey,
    envVariables.productApiMediaCdnBaseUrl,
    router,
  );

  // Top Products
  const topProductsMapped = topProducts
    ? formatFooterTopProducts(topProducts, process.env.NEXT_PUBLIC_HOME_LINK, {
        topProductsOverwrite: { column: 'right' },
      })
    : null;

  // Recently Viewed
  const recentlyViewedMapped = formatFooterRecentlyViewed({
    products,
    loading,
    setStorageItem,
    setProducts,
    cookieDomain: envVariables.cookieDomain,
  });

  const handleCookieAccept = () => {
    addToDataLayer({
      event: TrackingEventName.cookieInteraction,
      eventCategory: 'Cookie Policy Banner Activation',
      eventAction: 'Clicked Accept',
      eventLabel: 'Accept',
    });

    setCookieConsent();
  };

  const handleCookieModalActivation = useCallback(() => {
    addToDataLayer({
      event: TrackingEventName.cookieInteraction,
      eventCategory: 'Cookie Policy Banner Activation',
      eventAction: 'Cookie Banner Activation',
      eventLabel: 'Impression',
    });
  }, []);

  // Below useEffect is to fix the collision with C#, checking on the value of a `vp` cookie, if it matches the format [{"id": "7876", "added": 555}, {"id": "9182", "added": 5321}] we wanna clear it, otherwise we wanna leave it
  useEffect(() => {
    try {
      const cookies = global.document.cookie
        // split into an Array of key=value strings
        .split(';')
        // split each, so we have an Array of entries ([key, value])
        .map((str) => str.split('=').map((s) => s.trim()) as [string, string]);

      if (!cookies) return;

      for (const [key, value] of cookies) {
        if (key === 'vp') {
          const parsed = JSON.parse(value);
          if (
            parsed.length > 0 &&
            parsed.some((item: { id: string; added: number }) => item.id && item.added)
          ) {
            setDomainCookie('vp', '', { maxAge: 5 });
          }
        }
      }
    } catch (e) {
      // do nothing
    }
  }, []);

  // close modal on accept or URL change
  useEffect(() => {
    const cookiesAccepted = localStorage.getItem(cookieConsent) === 'true';
    const currentPath = router?.asPath || '';

    const handleRouteChange = ({ url }: { url: string }) => {
      if (showCookieConsent === true && url !== currentPath && cookiesAccepted) {
        setCookieConsent();
      }
    };

    router?.events?.on('routeChangeStart', handleRouteChange);
    handleRouteChange({ url: currentPath });

    if (!cookiesAccepted && !showCookieConsent) {
      setShowCookieConsent(true);
      handleCookieModalActivation();
    }

    return () => {
      router?.events?.off('routeChangeStart', handleRouteChange);
    };
  }, [router, handleCookieModalActivation, setCookieConsent, showCookieConsent]);

  const [affinityQuery, setAffinityQuery] = useState<string>();
  useEffect(() => {
    if (router?.query?.bid !== null) {
      setAffinityQuery(router?.query?.bid as string);
    }
  }, [router?.query?.bid]);

  // Set b2b cookie if query param is present
  useB2bQuery();

  // Klaviyo tracking on all pages
  useKlaviyoViewedPage();

  return (
    <>
      {isEnabled('FF_klaviyo') && <KlaviyoScript />}
      {process.env.NEXT_PUBLIC_FB_PIXEL_ID && isEnabled('FF_fb_pixel') && <FBPixel />}
      {affinityQuery && <DiscountModal affinityQuery={affinityQuery} />}
      {preview && (
        <div className="bg-primary p-6 font-bold">
          THIS IS PREVIEW MODE [<LinkWrap href="/api/endpreview">close</LinkWrap>]
        </div>
      )}
      <HeaderProvider
        NextImage={Image}
        NextLink={Link}
        NextRouter={router}
        {...envVariables}
        trackingPageType={trackingPageType}
      >
        <Header
          theme="light"
          immersive={isImmersive}
          navigation={{ navItems, featuredColumn, secondaryNavItems, promoBanner, links }}
          hideOn={[
            {
              el: '#quick-filter-filters',
              pages: [PAGE_TYPES.CategoryListing, PAGE_TYPES.ProductListing, PAGE_TYPES.Search],
            },
          ]}
          noSticky={trackingPageType === PAGE_TYPES.Product}
        />
        <main data-testid="site-main">{children}</main>
        {/* Container to keep background for cookie consent */}

        {displayCookieModalV1 && (
          <>
            <div
              className={cn(
                'absolute inset-0 z-[600] h-full w-full bg-transparent md:hidden',
                showCookieConsent ? 'initial' : 'hidden',
              )}
            />
            <CookiesModal
              show={showCookieConsent}
              onClick={handleCookieAccept}
              href={cookieModalHref}
            />
          </>
        )}
        {displayCookieModalV2 && (
          <div
            className={cn(
              'fixed inset-0 z-[600] flex flex-col items-center justify-end bg-black bg-opacity-80 backdrop-blur-sm',
              showCookieConsent ? 'initial' : 'hidden',
            )}
          >
            <CookiesModalV2
              show={showCookieConsent}
              href={cookieModalHref}
              onClick={handleCookieAccept}
            />
          </div>
        )}
        <Footer
          footerTags={null}
          footerNav={footerNav}
          footerSlider={
            [
              {
                sliderMapKey: 'footer-slider-map-key-1',
                slider: recentlyViewedMapped,
              },
              {
                sliderMapKey: 'footer-slider-map-key-2',
                slider: { ...topProductsMapped, displayEndCard: true },
              },
            ] as FooterProps['footerSlider']
          }
          footerPersistent={{
            helpLinks,
            copyright: '© Virgin Experience Days Ltd',
          }}
        />
      </HeaderProvider>
      <GiftFinder />
    </>
  );
};

export default VariationLayout;
