import { ProductInteractionProps } from '../components/common/ui/ProductV2/tracking';

import type { PortableTextTrackingFn } from './addTrackingEntryToLinks';

interface GlobalDataLayer {
  dataLayer?: unknown[];
}

type DataLayerEvent = {
  event: string;
  eventCategory?: string;
  eventAction?: string;
  eventLabel?: string;
};

function addToDataLayer(obj: Record<string, unknown>) {
  const globalWithDataLayer = global as unknown as GlobalDataLayer;

  if (!obj || !globalWithDataLayer.dataLayer) return;

  globalWithDataLayer.dataLayer = globalWithDataLayer.dataLayer || [];
  globalWithDataLayer.dataLayer.push(obj);
}

const makeDataLayerHandler = (partial: Partial<DataLayerEvent>) => (eventLabel?: string) => {
  const object: DataLayerEvent = { event: 'userInteraction', ...partial };
  if (eventLabel) object.eventLabel = eventLabel;
  addToDataLayer(object);
};

export const searchOpenToDataLayer = makeDataLayerHandler({
  eventCategory: 'Search',
  eventAction: 'Search Modal Opened',
});

export const searchToDataLayer = makeDataLayerHandler({
  eventCategory: 'Search',
  eventAction: 'Search Term Selected',
});

export const productToDataLayer = makeDataLayerHandler({
  eventCategory: 'Search',
  eventAction: 'Product Selected',
});

export const vpToDataLayer = makeDataLayerHandler({
  eventCategory: 'Search',
  eventAction: 'Recent Product Selected',
});

export const logoToDataLayer = makeDataLayerHandler({
  eventCategory: 'Header MegaNav',
  eventAction: 'Logo Clicked',
});

export const topLinkToDataLayer = makeDataLayerHandler({
  eventCategory: 'Header MegaNav',
  eventAction: 'Clicked TopLink',
});

export const basketToDataLayer = makeDataLayerHandler({
  eventCategory: 'Header MegaNav',
  eventAction: 'Clicked Basket',
});

export const openMobileNavToDataLayer = makeDataLayerHandler({
  eventCategory: 'Header MegaNav',
  eventAction: 'Opened Mobile Nav',
});

export const openDesktopNavToDataLayer = makeDataLayerHandler({
  eventCategory: 'Header MegaNav',
  eventAction: 'Opened Desktop Nav',
});

export const destinationLinkToDataLayer = makeDataLayerHandler({
  eventCategory: 'Header MegaNav',
  eventAction: 'Clicked Destination Link',
});

export const dismissPromoBannerToDataLayer = makeDataLayerHandler({
  eventCategory: 'Promo Banner',
  eventAction: 'Dismissed Promo Banner',
});

export const promoBannerLinkToDataLayer = makeDataLayerHandler({
  eventCategory: 'Promo Banner',
  eventAction: 'Clicked Promo Banner Link',
});

export const footerHelpLinkToDataLayer = makeDataLayerHandler({
  eventCategory: 'footer',
  eventAction: 'Clicked Link | Footer',
});

export const promoCardWidgetToDataLayer = makeDataLayerHandler({
  eventCategory: 'Promo Card',
  eventAction: 'Clicked Promo Card',
});

export const ctaLinkToDataLayer = (value: string, route: string) => {
  const object = {
    event: 'userInteraction',
    eventCategory: 'Header MegaNav',
    eventAction: `Clicked CTA Link | ${value}`,
    eventLabel: route,
  };
  addToDataLayer(object);
};

export function navLinkToDataLayer({ path = [], value }: { path: string[]; value: string }) {
  const object: Record<string, string> = {
    event: 'userInteraction',
    eventCategory: 'Header MegaNav',
    eventAction: `Clicked Menu | ${value}`,
  };
  if (path?.length) object.eventLabel = path.join(' > ');
  if (!path?.length && value) object.eventLabel = value;
  addToDataLayer(object);
}

export function footerLinkToDataLayer({ section, value }: { section?: string; value: string }) {
  const object = {
    event: 'userInteraction',
    eventCategory: 'footer',
    eventAction: `Clicked Link | ${section}`,
    eventLabel: value,
  };
  addToDataLayer(object);
}

export function trackEndCardToDataLayer(value: string) {
  const object = {
    event: 'userInteraction',
    eventCategory: 'footer',
    eventAction: 'Clicked Carousel End Card CTA  | Footer',
    eventLabel: value,
  };
  addToDataLayer(object);
}

export function trackEndCardOnReachedToDataLayer(value: string) {
  const object = {
    event: 'userInteraction',
    eventCategory: 'footer',
    eventAction: 'Reached End Card | Footer',
    eventLabel: value,
  };
  addToDataLayer(object);
}

/**
 * Product Card interactions
 * - Swipe to next image
 * - Swipe to previous image
 * - Click next image
 * - Click previous image
 * - Clicked on image
 * - Clicked on surrounding card
 */
export function trackProductCard(props: ProductInteractionProps) {
  const {
    // additional source data
    pageType,
    module,
    position,
    location,
    imageInteracted,
    interaction,
    // product data
    cardTitle: title,
    cardFullSku: fullSku,
    currentPrice: displayPrice,
    pastPrice: rrp,
    percentOff,
    reviews: { averageRating } = { averageRating: null },
    productPromo: promo,
  } = props;

  addToDataLayer({
    event: 'productCardInteraction',
    pc_page_type: pageType,
    pc_module: module,
    pc_position: position,
    pc_sku: fullSku,
    pc_title: title,
    pc_promo: promo?.config?.text,
    pc_average_rating: averageRating,
    pc_display_price: displayPrice,
    pc_rrp: rrp,
    pc_percent_off: percentOff,
    pc_location: location,
    pc_image_interacted: imageInteracted,
    pc_interaction: interaction,
  });
}

export const portableTextTracking: Record<PortableTextTrackingFn, (eventLabel?: string) => void> = {
  promoBanner: promoBannerLinkToDataLayer,
};
