import { useRouter } from 'next/compat/router';
import { useEffect } from 'react';
import { trackViewedPage } from '.';

export const useKlaviyoViewedPage = ({ title, url }: { title?: string; url?: string } = {}) => {
  const router = useRouter();

  useEffect(() => {
    const currentUrl = `${process.env.NEXT_PUBLIC_HOME_LINK}${router?.asPath}`;
    const query = router?.query ?? {};

    const utm: Record<string, string> = Object.keys(query).reduce(
      (acc, key) => {
        if (key.startsWith('utm_')) {
          acc[key] = query[key] as string;
        }
        return acc;
      },
      {} as Record<string, string>,
    );

    trackViewedPage({
      url: url ?? currentUrl,
      utm,
      title: title ?? global?.document?.title,
    });
  }, [router?.asPath, router?.query, title, url]);
};
