import { create } from 'zustand';

export type LayoutStore = {
  isMobileNavOpen: boolean;
  setIsMobileNavOpen: (isOpen: boolean) => void;
  toggleMobileNav: () => void;
};

export const useLayoutStore = create<LayoutStore>()((set) => ({
  isMobileNavOpen: false,
  setIsMobileNavOpen: (isOpen: boolean) => set(() => ({ isMobileNavOpen: isOpen })),
  toggleMobileNav: () => {
    set((state) => ({ isMobileNavOpen: !state.isMobileNavOpen }));
  },
}));
