export type ImageObject = {
  url?: string;
  alt?: string;
  dimensions?: { width?: number; height?: number };
};

// IMGIX provider URLs will go through processing to request an optimised image
export const imgixProviders: string[] = [
  'https://images.prismic.io/',
  'https://virginexperiencedaysdev.imgix.net/',
  'https://images.virginexperiencedays.co.uk/',
  'https://vedcdn.imgix.net',
];

// Non-IMGIX provider URLs will not go through any processing, and be requested as-is
export const imageProviders: string[] = [
  'https://ved.virgin.authxlab.com',
  'https://www.virginexperiencedays.co.uk',
  'https://maps.googleapis.com',
];
export const checkIfSrcExistsInProviders = (src: string): boolean => {
  if (typeof src !== 'string') return false;

  for (const provider of [...imgixProviders, ...imageProviders]) {
    if (src?.includes(provider)) return true;
  }

  return false;
};

export type GenerateOptimizedUrlParams = {
  src: string;
  width: number;
  quality: number;
  imageObject?: ImageObject;
};

export const generateOptimizedUrl = (args: GenerateOptimizedUrlParams): string => {
  const { src: imgSrc, width, quality, imageObject } = args;
  try {
    if (!imgSrc && !imageObject?.url) {
      throw new Error('src and imageObject.url are not defined');
    }
    // skip URL processing for non-imgix providers by checking if imgSrc starts with any provider
    if (imageProviders.some((provider) => imgSrc.startsWith(provider))) {
      return imgSrc;
    }
    if (imageObject?.url && (!imageObject?.dimensions?.width || !imageObject?.dimensions?.height)) {
      throw new Error('imageObject.dimensions.(width | height) are not defined');
    }

    if (imageObject?.url) {
      const baseUrl = removeQueryParamsFromUrl(imageObject.url);
      return addQueryParamsToUrl(baseUrl, {
        width: imageObject.dimensions.width,
        quality,
      });
    }
    const baseUrl = removeQueryParamsFromUrl(imgSrc);
    return addQueryParamsToUrl(baseUrl, {
      width,
      quality,
    });
  } catch (error) {
    console.error(`[Image]: ${imgSrc} - ${error}`);
    return imgSrc;
  }
};

export const removeQueryParamsFromUrl = (url: string): string => {
  if (!url) throw new Error('URL is not defined');

  const urlObject = new URL(url);
  // params to retain
  const rect = urlObject.searchParams.get('rect');

  urlObject.search = ''; // remove query params
  urlObject.hash = ''; // remove hash fragment

  // put back retained params
  if (rect) urlObject.searchParams.set('rect', rect);

  return urlObject.toString();
};

export function addQueryParamsToUrl(
  url: string,
  queryParams: Omit<GenerateOptimizedUrlParams, 'src'>,
): string {
  if (!url) throw new Error('URL is not defined');

  const urlObject = new URL(url);
  const { width, quality } = queryParams;

  urlObject.searchParams.set('auto', 'compress,format');
  if (typeof width === 'number') {
    urlObject.searchParams.set('w', width.toString());
  }

  if (typeof quality === 'number') {
    urlObject.searchParams.set('q', quality.toString());
  }

  urlObject.searchParams.set('fit', 'max');

  return urlObject.toString();
}
