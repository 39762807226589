import type { ChangeEvent, FormEvent } from 'react';

import { useHeaderContext } from '../../../context/HeaderContext';
import useAlgoliasearch from '../../../hooks/useAlgoliasearch';
import { useAutoFocusCallbackRef } from '../../../hooks/useAutoFocusCallbackRef';
import { useKeyPress } from '../../../hooks/useKeyPress';
import { generateRoute } from '../../../utils';
import { searchToDataLayer } from '../../../utils/addToDataLayer';
import RenderIcon from '../../common/icons/RenderIcon';

type SearchBarProps = {
  value?: string;
  withLabel?: boolean;
  iconSize?: string;
  id?: string;
  onClick?: (e: FormEvent<HTMLInputElement>) => void;
};

export const SearchBar = ({
  withLabel = false,
  iconSize = '15',
  id = 'headerSearchBar',
  ...rest
}: SearchBarProps) => {
  const { searchState, NextLink, homeLink, handleUpdateSearchState, handleResetSearchState } =
    useHeaderContext();
  const { siteSearch } = useAlgoliasearch();

  const { searchTerm = '', searchOverlay = false } = searchState;
  const searchInputRef = useAutoFocusCallbackRef({ deps: [searchOverlay] });

  // Handle key presses
  useKeyPress('Escape', handleResetSearchState);
  useKeyPress('Enter', () => submitSearch(searchTerm));

  // Blur search input when search icon button is clicked -- not needed, normally sending query will rerender whole page moving user to a different destination
  const submitSearch = (searchTerm: string) => {
    searchToDataLayer(searchTerm);
    handleUpdateSearchState({ searchOverlay: false, searchTerm });
    const initialRoute = generateRoute('search', { homeLink, isNextLink: !!NextLink });

    searchToDataLayer(searchTerm);
    handleUpdateSearchState({ searchOverlay: false, searchTerm: searchTerm });
    siteSearch(searchTerm, initialRoute).catch((error: unknown) => console.error(error));
  };

  return (
    <label className="flex grow flex-col" htmlFor={id}>
      {withLabel && (
        <span className="mb-4 font-normal leading-normal text-neutral-strong">
          Or <span className="font-semibold text-brand">search</span> what you&apos;re after
        </span>
      )}
      <div className="persistent-border-neutral relative mr-4 flex items-center gap-2 rounded-3xl border-2 border-solid bg-transparent">
        <button
          className="cursor-pointer pl-4"
          onClick={() => submitSearch(searchTerm ?? '')}
          aria-label="Submit search"
          type="submit"
        >
          <RenderIcon
            name="search"
            width={iconSize}
            height={iconSize}
            fill="currentColor"
            className="text-neutral-strong"
          />
        </button>
        <input
          id={id}
          ref={searchInputRef}
          placeholder="Search experiences..."
          value={searchTerm || ''}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleUpdateSearchState({ searchTerm: e.target.value })
          }
          className="dark:placeholder:text-neutral-faded-high-contrast h-11 w-full grow rounded-lg bg-transparent py-2 pr-4 text-left text-base leading-none text-neutral-strong outline-none placeholder:text-grey-500 lg:h-10 lg:text-sm lg:leading-none"
          {...rest}
        />
      </div>
    </label>
  );
};
