import { ErrorCodes, handleRichError } from '../utils/rich-error';
import { formatMediaInfo, formatPrice, formatReviewsInfo, formatUrl } from './formatPDPInfo';

import type { FormatterContext } from '../endpoint';
import type { BlogProduct, PaginatedResult } from '../types';

export function formatBlogProducts(
  response: PaginatedResult<unknown>,
  ctx: FormatterContext,
): PaginatedResult<BlogProduct> {
  try {
    if (typeof response !== 'object' || response === null) {
      throw new Error('Unable to format Blog Products. Invalid response format');
    }

    const formattedItems = response.items.reduce<BlogProduct[]>((acc, item) => {
      const formattedItem = formatBlogProductItem(item, ctx);
      if (formattedItem !== null) {
        acc.push(formattedItem);
      }
      return acc;
    }, []);

    return { ...response, items: formattedItems };
  } catch (error) {
    console.error(handleRichError(ErrorCodes.INVALID_RESPONSE_FORMAT, error));

    return { total: 0, items: [] };
  }
}

/**
 * Formats the response into the BlogProduct format.
 * @param item - The item object containing the product information.
 * @returns The formatted BlogProduct object or null if the product format is invalid.
 */
// biome-ignore lint/suspicious/noExplicitAny: TODO LP-7094: use the endpoint's schema so we have unknown -> BlogProduct
function formatBlogProductItem(item: any, ctx: FormatterContext): BlogProduct | null {
  if (typeof item !== 'object' || item === null) {
    console.error(
      handleRichError(
        ErrorCodes.INVALID_RESPONSE_FORMAT,
        new Error('Unable to format Blog Product item. Invalid item format'),
      ),
    );

    return null;
  }
  const { mediaCdnBaseUrl } = ctx;

  const formattedBlogProduct: BlogProduct = {
    id: String(item.id),
    title: item.title,
    sku: item.sku,
    url: formatUrl(item),
    slug: item.slug,
    description: item.description ?? null,
    shortDescription: item.shortDescription ?? null,
    status: item.status,
    media: formatMediaInfo(item, mediaCdnBaseUrl),
    reviews: formatReviewsInfo(item),
    price: formatPrice(item),
    locations: Array.isArray(item?.locations) ? item.locations : [],
    /**
     * Currently used for Location Override Text in Default Product Cards
     */
    categories: Array.isArray(item?.categories) ? item.categories : [],
    /**
     * Currently used for Category Badge in Blog Post Product Cards
     */
    categoryHierarchy: item.categoryHierarchy ?? null,
    isCollectionProduct: Boolean(item.isCollectionProduct),
  };

  return formattedBlogProduct;
}
