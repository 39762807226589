import { type ReactElement, type ReactNode, type SVGProps, cloneElement } from 'react';

import IconBlackFriday from './IconBlackFriday';
import IconBriefcase from './IconBriefcase';
import IconBurger from './IconBurger';
import IconCalendar from './IconCalendar';
import IconCamera from './IconCamera';
import IconCart from './IconCart';
import IconMobileChair from './IconChair';
import IconChairSmall from './IconChairSmall';
import IconChevron from './IconChevron';
import IconChristmasGift from './IconChristmasGift';
import IconMobileCoach from './IconCoach';
import IconCoachSmall from './IconCoachSmall';
import IconMobileCocktail from './IconCocktail';
import IconCocktailSmall from './IconCocktailSmall';
import IconMobileCompass from './IconCompass';
import IconCompassSmall from './IconCompassSmall';
import IconCross from './IconCross';
import IconEllipse from './IconEllipse';
import IconFavourite from './IconFavourite';
import IconMobileFootball from './IconFootball';
import IconFootballSmall from './IconFootballSmall';
import IconGift from './IconGift';
import IconGiftCard from './IconGiftCard';
import IconGiftFinder from './IconGiftFinder';
import IconGiftSmall from './IconGiftSmall';
import IconHeart from './IconHeart';
import IconHeartSmall from './IconHeartSmall';
import IconHelmetSmall from './IconHelmetSmall';
import IconLightning from './IconLightning';
import IconLocationPin from './IconLocationPin';
import IconLocationPinSmall from './IconLocationPinSmall';
import IconMobileLotusFlower from './IconLotusFlower';
import IconLotusFlowerSmall from './IconLotusFlowerSmall';
import IconMultiChoice from './IconMultiChoice';
import IconMobilePaintBrush from './IconPaintBrush';
import IconPaintbrushSmall from './IconPaintbrushSmall';
import IconMobilePaw from './IconPaw';
import IconPawSmall from './IconPawSmall';
import IconPhoto from './IconPhoto';
import IconMobilePlane from './IconPlane';
import IconPlaneSmall from './IconPlaneSmall';
import IconMobileSailboat from './IconSailboat';
import IconSailboatSmall from './IconSailboatSmall';
import IconSaleTag from './IconSaleTag';
import IconSearch from './IconSearch';
import IconSocial from './IconSocial';
import IconSparkle from './IconSparkle';
import IconStar from './IconStar';
import IconMobileTeaCup from './IconTeaCup';
import IconTeacupSmall from './IconTeacupSmall';
import IconMobileTent from './IconTent';
import IconTentSmall from './IconTentSmall';
import IconMobileTicket from './IconTicket';
import IconTicketSmall from './IconTicketSmall';
import IconUser from './IconUser';
import IconUserSmall from './IconUserSmall';
import RoundedSquareSvg from './RoundedSquareSvg';
import IconMobileCamera from './mobile/IconMobileCamera';
import IconMobileMultiChoice from './mobile/IconMobileMultiChoice';

import type { ThemeType } from '../../../types';

const desktopIconEntries: [string, ReactNode][] = [
  ['camera', <IconCamera key="camera" strokeWidth="2.2" />],
  ['chair', <IconChairSmall key="chair" />],
  ['coach', <IconCoachSmall key="coach" />],
  ['cocktail', <IconCocktailSmall key="cocktail" />],
  ['compass', <IconCompassSmall key="compass" />],
  ['football', <IconFootballSmall key="football" />],
  ['gift', <IconGiftSmall key="gift" />],
  ['gift-card', <IconGiftCard key="gift-card" />],
  ['gift-finder', <IconGiftFinder key="gift-finder" iconClass="h-5 w-5" />],
  ['heart', <IconHeartSmall key="heart" />],
  ['helmet', <IconHelmetSmall key="helmet" />],
  ['location-pin', <IconLocationPinSmall key="location-pin" />],
  ['lotus-flower', <IconLotusFlowerSmall key="lotus-flower" />],
  ['paintbrush', <IconPaintbrushSmall key="paintbrush" />],
  ['paw', <IconPawSmall key="paw" />],
  ['plane', <IconPlaneSmall key="plane" />],
  ['sail-boat', <IconSailboatSmall key="sail-boat" />],
  ['teacup', <IconTeacupSmall key="teacup" />],
  ['tent', <IconTentSmall key="tent" />],
  ['ticket', <IconTicketSmall key="ticket" />],
  ['user', <IconUser key="user" />],
  ['rounded-square', <RoundedSquareSvg key="rounded-square" />],
  ['ellipse', <IconEllipse key="ellipse" />],
  ['black-friday', <IconBlackFriday key="black-friday" />],
  ['favourite', <IconFavourite key="favourite" />],
  ['multi-choice', <IconMultiChoice key="multi-choice" strokeWidth="2" />],
  ['christmas-gift', <IconChristmasGift key="christmas-gift" />],
] as const;

const mobileIconEntries: [string, ReactNode][] = [
  ['briefcase', <IconBriefcase key="briefcase" />],
  ['burger', <IconBurger key="burger" />],
  ['calendar', <IconCalendar key="calendar" />],
  ['camera', <IconMobileCamera key="camera" strokeWidth="1" />],
  ['cart', <IconCart key="cart" />],
  ['chair', <IconMobileChair key="chair" strokeWidth="0.3" />],
  ['chevron-down', <IconChevron key="chevron-down" />],
  ['chevron-left', <IconChevron key="chevron-left" className="rotate-90" />],
  ['chevron-right', <IconChevron key="chevron-right" className="-rotate-90" />],
  ['chevron-up', <IconChevron key="chevron-up" className="-rotate-180" />],
  ['coach', <IconMobileCoach key="coach" strokeWidth="0.2" />],
  ['cocktail', <IconMobileCocktail key="cocktail" />],
  ['compass', <IconMobileCompass key="compass" />],
  ['cross', <IconCross key="cross" />],
  ['facebook', <IconSocial key="facebook" type="facebook" />],
  ['football', <IconMobileFootball key="football" />],
  ['gift', <IconGift key="gift" />],
  ['gift-card', <IconGiftCard key="gift-card" />],
  ['gift-finder', <IconGiftFinder key="gift-finder" iconClass="h-5 w-5" />],
  ['heart', <IconHeart key="heart" />],
  ['helmet', <IconHelmetSmall key="helmet" strokeWidth="0.1" />],
  ['instagram', <IconSocial key="instagram" type="instagram" />],
  ['location-pin', <IconLocationPin key="location-pin" />],
  ['lotus-flower', <IconMobileLotusFlower key="lotus-flower" />],
  ['paintbrush', <IconMobilePaintBrush key="paintbrush" />],
  ['paw', <IconMobilePaw key="paw" />],
  ['photo', <IconPhoto key="photo" />],
  ['plane', <IconMobilePlane key="plane" />],
  ['sail-boat', <IconMobileSailboat key="sail-boat" />],
  ['sale-tag', <IconSaleTag key="sale-tag" />],
  ['search', <IconSearch key="search" />],
  ['shock', <IconLightning key="shock" />],
  ['sparkle', <IconSparkle key="sparkle" />],
  ['star', <IconStar key="star" />],
  ['teacup', <IconMobileTeaCup key="teacup" />],
  ['tent', <IconMobileTent key="tent" strokeWidth="0.3" />],
  ['ticket', <IconMobileTicket key="ticket" />],
  ['twitter', <IconSocial key="twitter" type="twitter" />],
  ['user', <IconUserSmall key="user" />],
  ['youtube', <IconSocial key="youtube" type="youtube" />],
  ['multi-choice', <IconMobileMultiChoice key="multi-choice" strokeWidth="0.9" />],
] as const;

const desktopIcons = new Map(desktopIconEntries);
const mobileIcons = new Map(mobileIconEntries);

type DesktopIconName = (typeof desktopIconEntries)[number][0];
type MobileIconName = (typeof mobileIconEntries)[number][0];

export type IconName = DesktopIconName | MobileIconName;

type Props = {
  name: IconName;
  stroke?: string;
  fill?: string;
  theme?: ThemeType;
  themeColor?: string;
  device?: 'desktop' | 'mobile';
  shadedColor?: string;
  emptyColor?: string;
} & SVGProps<SVGSVGElement>;

const RenderIcon = ({ name, device = 'mobile', ...props }: Props) => {
  let icon: ReactNode | null = null;

  // return the icon based on the device or fallback to the other device if not found
  if (device === 'desktop') {
    icon = desktopIcons.get(name as DesktopIconName) ?? mobileIcons.get(name as MobileIconName);
  } else if (device === 'mobile') {
    icon = mobileIcons.get(name as MobileIconName) ?? desktopIcons.get(name as DesktopIconName);
  }

  return icon ? cloneElement(icon as ReactElement, props) : <IconPhoto {...props} />;
};

export default RenderIcon;
