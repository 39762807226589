import { useCallback, useEffect, useState } from 'react';

import { getPrismicLinkPath } from '../utils/cms/getPrismicLinkPath';
import { getApi } from '../utils/getApi';

const query = `[[at(document.type,"global")]]`;

type Params = {
  prismicRepoEndpoint: string;
  prismicToken: string;
};

type Suggestion = {
  name: string;
  route?: string;
};

// This hook should've been fired on CSR only
const useCMSGlobal = ({ prismicRepoEndpoint, prismicToken }: Params) => {
  const [topSuggestions, setTopSuggestions] = useState<Suggestion[]>([]);

  const fetchRefs = useCallback(
    async () => await getApi(prismicRepoEndpoint),
    [prismicRepoEndpoint]
  );

  const fetchCMSTopSuggestions = useCallback(async () => {
    const { refs } = await fetchRefs();
    if (refs) {
      // @ts-ignore
      const masterRef = refs?.find(({ isMasterRef }) => isMasterRef)?.ref;
      const { results } = await getApi(`${prismicRepoEndpoint}/documents/search?`, {
        ref: masterRef,
        access_token: prismicToken,
        q: query,
        src: 'apibrowser',
      });

      if (results) {
        const topSuggestions = results?.[0]?.data?.popular_searches;
        const newTopSuggestions: Suggestion[] = [];
        if (!Array.isArray(topSuggestions)) return;

        for (const item of topSuggestions) {
          if (item.title) {
            newTopSuggestions.push({
              name: item?.title,
              route: getPrismicLinkPath(item?.link) ?? '',
            });
          }
        }
        setTopSuggestions(newTopSuggestions.slice(0, 4));
      }
    }
  }, [fetchRefs, prismicRepoEndpoint, prismicToken, query]);

  useEffect(() => {
    fetchCMSTopSuggestions();
  }, [fetchCMSTopSuggestions]);

  return { topSuggestions };
};

// This hook is to provide SSR data for top Suggestions
// This needs to be implemented on SSR pages post release
export async function fetchCMSTopSuggestions({ prismicRepoEndpoint, prismicToken }: Params) {
  const { refs } = await getApi(prismicRepoEndpoint);

  if (refs) {
    // @ts-ignore
    const masterRef = refs?.find(({ isMasterRef }) => isMasterRef)?.ref;
    const { results } = await getApi(`${prismicRepoEndpoint}/documents/search?`, {
      ref: masterRef,
      access_token: prismicToken,
      q: query,
      src: 'apibrowser',
    });

    const newTopSuggestions: Suggestion[] = [];

    if (results) {
      const topSuggestions = results?.[0]?.data?.popular_searches;
      if (!Array.isArray(topSuggestions)) return;

      for (const item of topSuggestions) {
        if (item.title) {
          newTopSuggestions.push({
            name: item?.title,
            route: getPrismicLinkPath(item?.link) ?? '',
          });
        }
      }
    }
    return newTopSuggestions.slice(0, 4);
  }
}
export default useCMSGlobal;
