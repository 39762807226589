import type { ImagePropsSliceMachine } from '@virginexperiencedays/cms/slicemachine/ImmersiveHeroCarousel';

/**
 * Checks if immersive_hero_carousel exists in prismicSlices and if its first item
 * has all required image URLs present
 *
 * @param prismicSlices Array of prismic slices
 * @returns boolean indicating if immersive_hero_carousel exists and has valid image URLs
 */
export const hasImmersiveHeroCarousel = (
  prismicSlices?: Array<{ slice_type: string; items?: unknown[] }>,
): boolean => {
  if (!prismicSlices) {
    return false;
  }

  const immersiveSlice = prismicSlices.find(
    (slice) => slice.slice_type === 'immersive_hero_carousel',
  );

  // Return false if slice doesn't exist or has no items
  if (!immersiveSlice?.items?.length) {
    return false;
  }

  // Check that the first item has the required image URLs
  const firstItem = immersiveSlice.items[0] as ImagePropsSliceMachine;
  return Boolean(
    firstItem?.image_mobile?.url && firstItem?.image_tablet?.url && firstItem?.image_desktop?.url,
  );
};
