import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

// Utility class allowing to merge and overwrite Tailwind classes
// Usage:
// cn('text-red-500', 'text-blue-500') => 'text-blue-500'
// cn('text-red-500', 'text-blue-500', 'text-red-500') => 'text-blue-500'
// cn('p-4 m-4', isActive ? 'font-semibold' : 'font-normal', 'text-red-500') => 'p-4 m-4 font-semibold/font-normal(depends if active) text-red-500'
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
