import Script from 'next/script';
import {
  type KlaviyoAddedToCart,
  type KlaviyoCartItem,
  type KlaviyoCartItemProps,
  type KlaviyoCartProps,
  KlaviyoEvent,
  type KlaviyoProduct,
  type KlaviyoProductProps,
  type KlaviyoRemovedFromCart,
  type KlaviyoViewedItem,
  type KlaviyoViewedItemProps,
  type KlaviyoViewedPage,
  type KlaviyoViewedPageProps,
} from './types';

interface GlobalKlaviyo {
  klaviyo?: {
    identify: (data: { email: string }) => void;
    track: (eventName: string, data: Record<string, unknown>) => void;
    trackViewedItem: (data: Record<string, unknown>) => void;
  };
}

export const KLAVIYO_PUBLIC_KEY = process.env.NEXT_PUBLIC_KLAVIYO_PUBLIC_KEY;
export const CHECKOUT_URL = `${process.env.NEXT_PUBLIC_HOME_LINK}/checkout/express`;

/**
 * @see https://developers.klaviyo.com/en/docs/introduction_to_the_klaviyo_object
 */
export const KlaviyoScript = () => {
  if (!KLAVIYO_PUBLIC_KEY) {
    console.error('[Klaviyo] Missing public key');
    return null;
  }

  return (
    <>
      <Script
        id="klaviyo-script"
        strategy="afterInteractive"
        src={`https://static.klaviyo.com/onsite/js/${KLAVIYO_PUBLIC_KEY}/klaviyo.js`}
      />
      <Script
        id="klaviyo-script-object"
        strategy="afterInteractive"
        // biome-ignore lint/security/noDangerouslySetInnerHtml: third party requirement
        dangerouslySetInnerHTML={{
          __html:
            '!function(){if(!window.klaviyo){window._klOnsite=window._klOnsite||[];try{window.klaviyo=new Proxy({},{get:function(n,i){return"push"===i?function(){var n;(n=window._klOnsite).push.apply(n,arguments)}:function(){for(var n=arguments.length,o=new Array(n),w=0;w<n;w++)o[w]=arguments[w];var t="function"==typeof o[o.length-1]?o.pop():void 0,e=new Promise((function(n){window._klOnsite.push([i].concat(o,[function(i){t&&t(i),n(i)}]))}));return e}}})}catch(n){window.klaviyo=window.klaviyo||[],window.klaviyo.push=function(){var n;(n=window._klOnsite).push.apply(n,arguments)}}}}();',
        }}
      />
    </>
  );
};

// Custom Event
export const trackViewedPage = ({ url, utm, title }: KlaviyoViewedPageProps) => {
  try {
    const globalWithKlaviyo = global as unknown as GlobalKlaviyo;

    const data: KlaviyoViewedPage = {
      PageTitle: title,
      Url: url,
      Utm: utm,
    };

    globalWithKlaviyo?.klaviyo?.track(KlaviyoEvent.ViewedPage, data);
  } catch (e) {
    console.error('[Klaviyo] Unable to track viewed page', e);
  }
};

/**
 * @see https://developers.klaviyo.com/en/docs/guide_to_integrating_a_platform_without_a_pre_built_klaviyo_integration#viewed-product-tracking-snippet
 */
export const trackViewedItem = ({
  categories,
  displayPrice,
  imageUrl,
  rrp,
  sku,
  slug,
  title,
  type,
  url,
  isCollectionProduct = false,
}: KlaviyoViewedItemProps) => {
  const productType = isCollectionProduct ? 'collection' : 'product';
  try {
    const globalWithKlaviyo = global as unknown as GlobalKlaviyo;

    const data: KlaviyoViewedItem = {
      Title: title,
      ItemId: slug,
      ImageUrl: imageUrl,
      Url: url,
      SKU: sku,
      Categories: categories,
      Type: type ?? productType,
      IsCollectionProduct: isCollectionProduct,
      Metadata: {
        Price: displayPrice,
        CompareAtPrice: rrp,
      },
    };

    globalWithKlaviyo?.klaviyo?.trackViewedItem(data);
  } catch (e) {
    console.error('[Klaviyo] Unable to track viewed item', e);
  }
};

/**
 * @see https://developers.klaviyo.com/en/docs/guide_to_integrating_a_platform_without_a_pre_built_klaviyo_integration#viewed-product-tracking-snippet
 */
export const trackViewedProduct = ({
  categories,
  displayPrice,
  imageUrl,
  rrp,
  sku,
  slug,
  title,
  url,
  isCollectionProduct = false,
}: KlaviyoProductProps) => {
  try {
    const globalWithKlaviyo = global as unknown as GlobalKlaviyo;

    const data: KlaviyoProduct = {
      ProductName: title,
      ProductID: slug,
      SKU: sku,
      Categories: categories,
      ImageURL: imageUrl,
      Url: url,
      Price: displayPrice,
      CompareAtPrice: rrp,
      IsCollectionProduct: isCollectionProduct,
    };

    globalWithKlaviyo?.klaviyo?.track(KlaviyoEvent.ViewedProduct, data);
  } catch (e) {
    console.error('[Klaviyo] Unable to track viewed product', e);
  }
};

/**
 * @see https://developers.klaviyo.com/en/docs/guide_to_integrating_a_platform_without_a_pre_built_klaviyo_integration#added-to-cart-tracking-snippet
 */
export const trackAddedToCart = ({
  categories,
  checkoutUrl,
  displayPrice,
  imageUrl,
  items,
  qty,
  rrp,
  sku,
  slug,
  title,
  totalAmount,
  url,
  isCollectionProduct = false,
}: KlaviyoCartProps) => {
  try {
    const globalWithKlaviyo = global as unknown as GlobalKlaviyo;

    const data: KlaviyoAddedToCart = {
      $value: totalAmount,
      AddedItemProductName: title,
      AddedItemProductID: slug,
      AddedItemSKU: sku,
      AddedItemCategories: categories,
      AddedItemImageURL: imageUrl,
      AddedItemURL: url,
      AddedItemPrice: displayPrice,
      AddedItemCompareAtPrice: rrp,
      AddedItemQuantity: qty,
      AddedItemIsCollectionProduct: isCollectionProduct,
      ItemNames: items.map(({ title }) => title),
      CheckoutURL: checkoutUrl ?? CHECKOUT_URL,
      Items: Array.isArray(items) && items.length ? items.map(mapKlaviyoCartItem) : [],
    };

    globalWithKlaviyo?.klaviyo?.track(KlaviyoEvent.AddedToCart, data);
  } catch (e) {
    console.error('[Klaviyo] Unable to track added to cart', e);
  }
};

// Custom Event
export const trackRemovedFromCart = ({
  categories,
  checkoutUrl,
  displayPrice,
  imageUrl,
  items,
  qty,
  rrp,
  sku,
  slug,
  title,
  totalAmount,
  url,
  isCollectionProduct = false,
}: KlaviyoCartProps) => {
  try {
    const globalWithKlaviyo = global as unknown as GlobalKlaviyo;

    const data: KlaviyoRemovedFromCart = {
      $value: totalAmount,
      RemovedItemProductName: title,
      RemovedItemProductID: slug,
      RemovedItemSKU: sku,
      RemovedItemCategories: categories,
      RemovedItemImageURL: imageUrl,
      RemovedItemURL: url,
      RemovedItemPrice: displayPrice,
      RemovedItemCompareAtPrice: rrp,
      RemovedItemQuantity: qty,
      RemovedItemIsCollectionProduct: isCollectionProduct,
      ItemNames: items.map(({ title }) => title),
      CheckoutURL: checkoutUrl ?? CHECKOUT_URL,
      Items: Array.isArray(items) && items.length ? items.map(mapKlaviyoCartItem) : [],
    };

    globalWithKlaviyo?.klaviyo?.track(KlaviyoEvent.RemovedFromCart, data);
  } catch (e) {
    console.error('[Klaviyo] Unable to track removed from cart', e);
  }
};

const mapKlaviyoCartItem = ({
  categories,
  displayPrice,
  imageUrl,
  qty,
  rowTotal,
  rrp,
  sku,
  slug,
  title,
  url,
  isCollectionProduct = false,
}: KlaviyoCartItemProps): KlaviyoCartItem => ({
  ProductID: slug,
  SKU: sku,
  ProductName: title,
  Quantity: qty,
  ItemPrice: displayPrice,
  ItemCompareAtPrice: rrp,
  RowTotal: rowTotal ?? qty * displayPrice,
  ProductURL: url,
  ImageURL: imageUrl,
  ProductCategories: categories,
  IsCollectionProduct: isCollectionProduct,
});
