import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { AriaLabel, TestId } from '../../../enums';
import { useLayoutStore } from '../../../store';
import { cn, ctaLinkToDataLayer } from '../../../utils';
import LinkList from '../../common/LinkList';
import StarRating from '../../common/StarRating';
import Divider from '../../common/ui/Divider';
import ButtonLink from '../../common/ui/buttons/ButtonLink';

import NavList, { type NavStackState } from './NavigationBar/NavList';

import type { HeaderProps, LinkType } from '../../../types';

type MegaNavMobileProps = {
  navItems: HeaderProps['navigation']['navItems'];
  secondaryNavItems: HeaderProps['navigation']['secondaryNavItems'];
  links: HeaderProps['navigation']['links'];
};

const DEFAULT_NAV_STATE: Readonly<NavStackState> = { currentLvl: 1, selectedStack: [] };

const MegaNavMobileComponent = ({
  navItems = [],
  secondaryNavItems = [],
  links,
}: MegaNavMobileProps) => {
  const refContainer = useRef<HTMLDivElement>(null);

  const { isMobileNavOpen, setIsMobileNavOpen } = useLayoutStore(
    useShallow((state) => ({
      isMobileNavOpen: state.isMobileNavOpen,
      setIsMobileNavOpen: state.setIsMobileNavOpen,
    })),
  );

  const [activeNavStack, setActiveNavStack] = useState(DEFAULT_NAV_STATE);
  const [isMounted, setIsMounted] = useState(false);

  const myVoucherLink = links?.myVoucher;
  const bestSellersLink = links?.bestSellers;

  const closeNav = () => {
    setIsMobileNavOpen(false);
  };

  const handleTrackCtaAndReset = (value: string, route: string) => {
    ctaLinkToDataLayer(value, route);
    closeNav();
  };

  const handleRestoreScroll = useCallback(() => {
    if (!refContainer?.current) return;

    refContainer.current.scrollTop = 0;
  }, []);

  useEffect(() => {
    // to avoid lack of x transition on mount
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isMobileNavOpen) {
      handleRestoreScroll();
    } else {
      setActiveNavStack(DEFAULT_NAV_STATE);
    }
  }, [handleRestoreScroll, isMobileNavOpen]);

  return (
    <nav
      id="mega-nav-mobile"
      className={cn(
        'fixed left-0 top-0 h-full w-full pt-[calc(var(--header-h-mobile-large)+0.5rem)] text-neutral overflow-hidden bg-background-page z-30',
        'will-change-transform transform translate-x-full transition-transform duration-150 ease-out',
        'lg:hidden',
        isMounted && isMobileNavOpen && 'translate-x-0 duration-250',
      )}
      aria-hidden={!isMobileNavOpen}
      aria-label={AriaLabel.MegaNavMobile}
      tabIndex={isMobileNavOpen ? 0 : -1}
    >
      <div
        ref={refContainer}
        className={cn([
          'relative h-full overflow-y-auto p-4 pb-10',
          'before:pointer-events-none before:fixed before:left-0 before:top-[calc(var(--header-h-mobile-large)+0.5rem)] before:z-10 before:h-3 before:w-full before:bg-[linear-gradient(180deg,rgba(255,255,255,1)_20%,rgba(255,255,255,0.7)_60%,rgba(255,255,255,0)_100%)]',
          'dark:before:bg-[linear-gradient(180deg,rgba(15,19,26,1)_20%,rgba(15,19,26,0.7)_60%,rgba(15,19,26,0)_100%)]',
        ])}
      >
        <nav data-testid={TestId.MobileNavigation} className="relative">
          <NavList
            navItems={navItems}
            activeNavStack={activeNavStack}
            navLvl={DEFAULT_NAV_STATE.currentLvl}
            setActiveNavStack={setActiveNavStack}
            handleRestoreScroll={handleRestoreScroll}
            handleCloseAndResetNav={closeNav}
          />
        </nav>

        {activeNavStack.currentLvl === 1 && (
          <>
            <Divider className="my-2" />
            <div>
              <LinkList
                links={secondaryNavItems.flat() as LinkType[]}
                data-testid={TestId.SecondaryNavList}
                handleCloseAndResetNav={closeNav}
              />
              <Divider className="mb-6" />
              <StarRating
                title="Loved by over 1 million customers"
                rating={5}
                className="mb-4"
                shadedColor="#2b3240"
                data-testid={TestId.MainUsp}
              />
              {!!myVoucherLink && (
                <ButtonLink
                  data-testid={TestId.VoucherLink}
                  href={myVoucherLink}
                  onClick={() =>
                    handleTrackCtaAndReset('Already have a voucher?', myVoucherLink as string)
                  }
                  className="mb-2"
                >
                  Already have a voucher?
                </ButtonLink>
              )}
              {!!bestSellersLink && (
                <ButtonLink
                  href={bestSellersLink}
                  isPrimary
                  data-testid={TestId.MainCtaLink}
                  onClick={() =>
                    handleTrackCtaAndReset('Shop Bestsellers', bestSellersLink as string)
                  }
                >
                  Shop Bestsellers
                </ButtonLink>
              )}
              <p className="mt-4 text-center text-[13px] font-semibold leading-[13px] text-neutral">
                Free instant email delivery available
              </p>
            </div>
          </>
        )}
      </div>
    </nav>
  );
};

export const MegaNavMobile = memo(MegaNavMobileComponent);
