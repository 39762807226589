import { useState, useEffect } from 'react';

/**
 * A custom hook that returns a boolean flag indicating whether
 * the component has been mounted. The flag remains true once set,
 * implying the component is fully mounted.
 *
 * @returns {boolean} A boolean value indicating if the component has mounted.
 */
export const useIsMounted = () => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return isMounted;
};
