import type { EnvVariables } from '@virginexperiencedays/header-2024/src';
import type { EnvVariables as SlimHeaderEnvVars } from '@virginexperiencedays/slim-header/src';

// TODO LP-6336 - removed from header-2024 so need to be removed when all PRs are merged
type PromoVars = {
  isBlackFriday?: string;
  isChristmas?: string;
  isCyberMonday?: string;
  isEaster?: string;
  isMothersDay?: string;
  isFathersDay?: string;
  isValentinesDay?: string;
  blackFridayBadge?: string;
  cyberMondayBadge?: string;
  christmasBadge?: string;
  valentinesDayBadge?: string;
};

export const envVariables: EnvVariables & PromoVars & Pick<SlimHeaderEnvVars, 'isSlimHeader'> = {
  prismicRepoEndpoint: process.env.NEXT_PUBLIC_PRISMIC_REPO_ENDPOINT,
  prismicToken: process.env.NEXT_PUBLIC_PRISMIC_API_TOKEN,
  homeLink: process.env.NEXT_PUBLIC_HOME_LINK,
  siteLogoDefault: process.env.NEXT_PUBLIC_SITE_LOGO_DEFAULT,
  siteLogoWhite: process.env.NEXT_PUBLIC_SITE_LOGO_WHITE,
  algoliaAppId: process.env.NEXT_PUBLIC_PRODUCTS_ALGOLIA_APP_ID,
  algoliaSearchApiKey: process.env.NEXT_PUBLIC_PRODUCTS_ALGOLIA_SEARCH_API_KEY,
  algoliaSearchIndex: process.env.NEXT_PUBLIC_PRODUCTS_ALGOLIA_INDEX_NAME,
  algoliaSuggestionIndex: `${process.env.NEXT_PUBLIC_PRODUCTS_ALGOLIA_INDEX_NAME}_query_suggestions`,
  productApiUrl: process.env.NEXT_PUBLIC_BACKEND_API_URL,
  productApiKey: process.env.NEXT_PUBLIC_BACKEND_API_KEY_FOR_HEADER,
  productApiMediaCdnBaseUrl: process.env.NEXT_PUBLIC_BACKEND_API_MEDIA_CDN_BASE_URL,
  cookieDomain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
  isProductCardsV2: process.env.NEXT_PUBLIC_FF_PRODUCT_CARDS_V2,
  isFeaturedColumnFallback: process.env.NEXT_PUBLIC_FF_NAV_FEATURED_COLUMN_FALLBACK,
  isSlimHeader: process.env.NEXT_PUBLIC_FF_SLIM_HEADER,
};

export const navigationServiceUrl = process?.env?.NEXT_PUBLIC_NAVIGATION_SERVICE_BASE_URL;
