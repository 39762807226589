import { useTheme } from '../../context/ThemeContext';
import { useLayoutStore } from '../../store';
import { cn } from '../../utils';
import SearchBarButton from '../common/ui/buttons/SearchBarButton';

type Props = {
  isImmersive?: boolean;
  isLarge?: boolean;
  onSearchClick: () => void;
};

export const HeaderImmersiveSearch = ({ isImmersive, isLarge, onSearchClick }: Props) => {
  const { immersive } = useTheme();
  const isMobileNavOpen = useLayoutStore((state) => state.isMobileNavOpen);

  return (
    <div
      className={cn(
        style.container._,
        isLarge && style.container.isVisible,
        !isImmersive && isMobileNavOpen && style.container.hasDelayIn,
        immersive && !isMobileNavOpen && style.container.noTransition,
      )}
    >
      <SearchBarButton
        className="w-full"
        placeholder="Search experiences..."
        onClick={onSearchClick}
        isDark={immersive && !isMobileNavOpen}
      />
    </div>
  );
};

const style = {
  container: {
    _: [
      'invisible absolute left-0 top-[calc(100%+8px)] z-50 w-full px-4 opacity-0 transition-[opacity,visibility] delay-[0s,0.15s] duration-[0.15s,0s] ease-out',
      'lg:hidden',
    ],
    hasDelayIn: 'duration-[0.25s,0s] delay-[0.15s,0s]',
    isVisible: 'visible opacity-100 delay-[0s,0s]',
    noTransition: 'delay-[0s,0s] duration-[0s,0s]',
  },
};
