/**
 * Creates a full URL by combining the home URL with the provided URL.
 * If the provided URL already starts with 'http://' or 'https://', it returns the URL as is.
 * Otherwise, it normalizes the URL by ensuring it starts with a '/' and then concatenates it with the home URL.
 *
 * @param homeUrl - The base URL to be used as the home URL.
 * @param url - The URL to be combined with the home URL.
 * @returns The full URL as a string.
 */
export const createAbsoluteUrl = (homeUrl: string, url: string) => {
  // If the URL already starts with http/https, return it as is
  if (url.startsWith('http://') || url.startsWith('https://')) return url;

  const normalizedUrl = url.startsWith('/') ? url : `/${url}`;

  return `${homeUrl}${normalizedUrl}`;
};
