import { debounce } from 'lodash';
import { type RefObject, useRef, useState } from 'react';

import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect';
import { useWindowWidth } from './useWindowWidth';

type StickyOptions = {
  mobileOnly?: boolean;
  placement?: 'top' | 'bottom';
  start?: 'viewport' | 'self';
};

const TAILWIND_LG_BREAKPOINT = 992;

/**
 * A React hook that provides sticky/fixed positioning behavior for an HTML element as the user scrolls.
 *
 * @template T - The type of HTML element to make sticky (extends HTMLElement)
 * @param options - Configuration options for the sticky behavior
 * @param options.mobileOnly - When true, sticky behavior will only be applied on mobile devices
 * @param options.placement - Where the element should be fixed to ('top' by default)
 * @param options.start - When the sticky behavior should start ('self' by default, which uses the element's
 *                        position, or can be set to another value to start at scroll position 0)
 *
 * @returns A tuple containing:
 * - A ref object to attach to the DOM element that should become sticky
 * - A boolean indicating if the element is currently in sticky/fixed state
 *
 * @example
 * ```tsx
 * const [refSticky, isSticky] = useStickyFixed<HTMLDivElement>({ placement: 'top' });
 *
 * return (
 *   <div ref={refSticky} className={isSticky ? 'sticky-active' : ''}>
 *     This element will become sticky when scrolled
 *   </div>
 * );
 * ```
 *
 * @see This hook was added to address sticky positioning issues observed on iOS 18.3.1. It might not
 * be necessary to use this hook if the underlying issue is resolved in future iOS versions.
 * See related Jira issue: {@link https://virginexperiencedays.atlassian.net/browse/LP-7732 LP-7732}
 */
export const useStickyFixed = <T extends HTMLElement>(
  options: StickyOptions = {},
): [RefObject<T>, boolean] => {
  const { mobileOnly, placement = 'top', start = 'self' } = options;

  const refEl = useRef<T>(null);
  const refPlaceholder = useRef<HTMLDivElement | null>();

  const [isSticky, setIsSticky] = useState(false);

  const { width } = useWindowWidth();

  const isMobile = (width || 0) < TAILWIND_LG_BREAKPOINT;

  useIsomorphicLayoutEffect(() => {
    const el = refEl.current;
    const parent = el?.parentElement;

    if (!el || !parent) return;

    // create a placeholder element to maintain the layout when the element is fixed
    const createPlaceholder = (el: HTMLElement) => {
      const placeholder = document.createElement('div');

      placeholder.style.display = 'none';
      placeholder.style.width = `${el.clientWidth}px`;
      placeholder.style.height = `${el.clientHeight}px`;
      placeholder.style.margin = getComputedStyle(el).margin;
      parent.insertBefore(placeholder, el);

      return placeholder;
    };

    const removePlaceholder = () => {
      refPlaceholder.current?.remove();
      refPlaceholder.current = null;
    };

    const handleScroll = () => {
      if (!refPlaceholder.current) return;

      // some browsers have a negative scrollY value when scrolling up
      const scrollY = Math.abs(window.scrollY);
      // we prefer the placeholder's offsetTop as it stays the same when the element is fixed
      const elOffsetTop = refPlaceholder.current.offsetTop || el.offsetTop;
      const minY = start === 'self' ? elOffsetTop : 0;
      let maxY: number;

      if (placement === 'top') {
        maxY = minY + parent.clientHeight - el.clientHeight;
      } else {
        maxY = elOffsetTop + el.clientHeight - window.innerHeight;
      }

      if (scrollY >= minY && scrollY <= maxY) {
        setIsSticky(true);
        el.style.position = 'fixed';
        el.style[placement] = `env(safe-area-inset-${placement})`;
        el.style.left = `${getComputedStyle(el).marginLeft}px`;
        el.style.width = '100%';
        refPlaceholder.current.style.display = 'block';
      } else {
        cleanUpEl();
      }
    };

    const cleanUpEl = () => {
      if (!refPlaceholder.current) return;

      setIsSticky(false);
      el.style.position = '';
      el.style[placement] = '';
      el.style.left = '';
      el.style.width = '';
      refPlaceholder.current.style.display = 'none';
    };

    const debouncedHandleScroll = debounce(handleScroll, 16);

    const removeListeners = () => {
      window.removeEventListener('scroll', debouncedHandleScroll);
      window.removeEventListener('resize', debouncedHandleScroll);
    };

    // removing listeners and placeholder on hook re-run
    removeListeners();
    removePlaceholder();

    // don't apply sticky behavior on desktop if mobileOnly is true
    if (mobileOnly && !isMobile) {
      cleanUpEl();
      return;
    }

    refPlaceholder.current = createPlaceholder(el);

    window.addEventListener('scroll', debouncedHandleScroll);
    window.addEventListener('resize', debouncedHandleScroll);

    handleScroll();

    return () => {
      removeListeners();
      removePlaceholder();
    };
  }, [isMobile, mobileOnly, placement, start]);

  return [refEl, isSticky];
};
