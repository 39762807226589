import { isEnableServer } from '@virginexperiencedays/feature-flags/src';

import type { FormatterContext } from '../endpoint';
import type {
  Media,
  MediaInfo,
  PDPInfo,
  PaginatedResult,
  PriceInfo,
  ProductChoiceInfo,
  ReviewsInfo,
  Validity,
  Video,
} from '../types';
import { ErrorCodes, handleRichError } from '../utils/rich-error';

const PRODUCT_DAYS_NEW = 30;

export function formatPDPInfo(
  response: PaginatedResult<unknown>,
  ctx: FormatterContext,
): PaginatedResult<PDPInfo> {
  try {
    if (typeof response !== 'object' || response === null) {
      throw new Error('Unable to format PDP Info. Invalid response format');
    }

    const formattedItems = response.items.reduce<PDPInfo[]>((acc, item) => {
      const formattedItem = formatPDPInfoItem(item, ctx);
      if (formattedItem !== null) {
        acc.push(formattedItem);
      }
      return acc;
    }, []);

    return { ...response, items: formattedItems };
  } catch (error) {
    console.error(handleRichError(ErrorCodes.INVALID_RESPONSE_FORMAT, error));

    return { total: 0, items: [] };
  }
}

/**
 * Formats the PDP response into the PDPInfo format.
 * @param item - The item object containing the product information.
 * @returns The formatted PDPInfo object or null if the product format is invalid.
 */
// biome-ignore lint/suspicious/noExplicitAny: TODO LP-7094: use the endpoint's schema so we have unknown -> PDPInfo
export function formatPDPInfoItem(item: any, ctx: FormatterContext): PDPInfo | null {
  if (typeof item !== 'object' || item === null) {
    console.error(
      handleRichError(
        ErrorCodes.INVALID_RESPONSE_FORMAT,
        new Error('Unable to format PDP item. Invalid item format'),
      ),
    );

    return null;
  }
  const { mediaCdnBaseUrl } = ctx;

  const pdpInfo: PDPInfo = {
    categories: Array.isArray(item?.categories) ? item.categories : [],
    categoryHierarchy: item.categoryHierarchy ?? null,
    dateActivated: item.dateActivated ?? null,
    deliveryOptions: Array.isArray(item?.deliveryOptions) ? item.deliveryOptions : [],
    description: item.description ?? null,
    shortDescription: item.shortDescription ?? null,
    evoucherOnly: Boolean(item.evoucherOnly),
    facets: item.facets,
    id: String(item.id),
    info: item.info,
    isBundle: Boolean(item?.isBundle),
    isCollectionProduct: Boolean(item.isCollectionProduct),
    isExclusive: Boolean(item.isExclusive),
    isNew: calculateIsNew(item.dateActivated, PRODUCT_DAYS_NEW),
    locations: Array.isArray(item?.locations) ? item.locations : [],
    media: formatMediaInfo(item, mediaCdnBaseUrl),
    numberOfPeople: typeof item?.numberOfPeople === 'number' ? item.numberOfPeople : null,
    price: formatPrice(item),
    productChoices: formatProductChoices(item, mediaCdnBaseUrl),
    promocode: item.promocode,
    reviews: formatReviewsInfo(item),
    searchIndex: item?.searchindex ?? null,
    seo: item.seo,
    sku: item.sku,
    slug: item.slug,
    status: item.status,
    stock: item?.stock ?? null,
    suppliers: Array.isArray(item?.suppliers) ? item.suppliers : [],
    title: item.title,
    unitsAvailableIsPublic: item?.unitsAvailableIsPublic ?? null,
    url: formatUrl(item),
    validity: formatValidity(item),
    voucherPartTitles: item?.voucherPartTitles ?? null,
  };

  return pdpInfo;
}

/**
 * Formats the price information from the response object.
 * @param response - The response object containing the price information.
 * @returns The formatted PriceInfo object or null if the response does not contain price information.
 */
// biome-ignore lint/suspicious/noExplicitAny: TODO LP-7094: use the endpoint's schema so we have unknown -> PDPInfo
export function formatPrice(response: any): PriceInfo | null {
  if (!response?.price) {
    console.error(
      handleRichError(
        ErrorCodes.INVALID_RESPONSE_FORMAT,
        new Error('Unable to format price. Invalid price format'),
      ),
    );

    return null;
  }

  const price =
    Array.isArray(response?.price) && response.price.length ? response.price[0] : response.price;

  return {
    rrp: price?.rrp ?? null,
    displayPrice: price?.displayPrice ?? null,
    percentOff: price?.percentOff ?? null,
    fixedAmountOff: price?.fixedAmountOff ?? null,
    currency: price?.currency ?? '',
  };
}

/**
 * Formats the media information from the response object.
 * @param response - The response object containing media information.
 * @param domain - The domain to prepend to the image URLs.
 * @returns The formatted media information.
 */
// biome-ignore lint/suspicious/noExplicitAny: TODO LP-7094: use the endpoint's schema so we have unknown -> PDPInfo
export function formatMediaInfo(response: any, domain: string): MediaInfo {
  if (!response?.media) {
    console.warn(
      handleRichError(
        ErrorCodes.INVALID_RESPONSE_FORMAT,
        new Error("Media doesn't exist on the response. Falling back..."),
      ),
    );
  }

  const media = response?.media || {};
  const images = media?.images || [];

  let mainImage: Media | null = null;
  if (media?.mainImage) {
    mainImage = {
      ...media.mainImage,
      url: `${domain}/${media.mainImage.url}`,
    };
  }

  const formattedImages = images.map((img: Media) => ({
    ...img,
    url: `${domain}/${img.url}`,
  }));

  const video: Video =
    isEnableServer('FF_new_pdp_video') && media?.video?.videoId ? media.video : null;

  if (mainImage) {
    return { mainImage, images: formattedImages, video };
  }

  const [mainImageFallback, ...otherImages] = formattedImages;

  return { mainImage: mainImageFallback, images: otherImages, video };
}

/**
 * Formats the reviews information from the response object.
 * @param {any} response - The response object containing the reviews information.
 * @returns {ReviewsInfo} - The formatted reviews information.
 */
// biome-ignore lint/suspicious/noExplicitAny: TODO LP-7094: use the endpoint's schema so we have unknown -> PDPInfo
export function formatReviewsInfo(response: any): ReviewsInfo {
  if (!response?.reviews) {
    console.warn(
      handleRichError(
        ErrorCodes.INVALID_RESPONSE_FORMAT,
        new Error("Reviews don't exist on the response. Falling back..."),
      ),
    );
  }

  const averageRating =
    // Wanna catch both null and undefined
    response?.reviews?.averageRating != null && !Number.isNaN(response?.reviews?.averageRating)
      ? response.reviews.averageRating
      : null;

  // Wanna catch both null and undefined
  const count =
    response?.reviews?.count != null && !Number.isNaN(response?.reviews?.count)
      ? response.reviews.count
      : null;

  return { averageRating, count };
}

/**
 * Calculates whether a given date is considered "new" based on the number of days specified.
 * @param dateActivated - The date when the item was activated.
 * @param daysIsNew - The number of days to consider an item as "new".
 * @returns A boolean indicating whether the item is considered "new".
 */
export function calculateIsNew(dateActivated: string, daysIsNew: number): boolean {
  try {
    const date = new Date(dateActivated);
    if (Number.isNaN(date.getTime())) return false;

    const now = new Date();
    const diff = now.getTime() - date.getTime();
    const diffDays = diff / (1000 * 60 * 60 * 24);

    return diffDays < daysIsNew;
  } catch (error) {
    console.error(handleRichError(ErrorCodes.INVALID_RESPONSE_FORMAT, error));
    return false; // Assuming false as a safe fallback
  }
}

/**
 * Formats the validity information from the response object.
 * @param response - The response object containing the validity information.
 * @returns An object with the formatted validity information.
 */
// biome-ignore lint/suspicious/noExplicitAny: TODO LP-7094: use the endpoint's schema so we have unknown -> PDPInfo
export function formatValidity(response: any): Validity {
  if (typeof response.validity === 'object' && response.validity !== null) {
    const { validityMonths, expiryDate } = response.validity;
    return {
      validityMonths: typeof validityMonths === 'number' ? validityMonths : null,
      expiryDate: typeof expiryDate === 'string' || expiryDate instanceof Date ? expiryDate : null,
    };
  }

  return { expiryDate: null, validityMonths: null };
}

/**
 * Formats the product choices response into a list of ProductChoiceInfo.
 *
 * @param response - The product choices response object.
 * @param imageDomain - The domain for the product images.
 * @returns An array of formatted ProductChoiceInfo objects.
 */
// biome-ignore lint/suspicious/noExplicitAny: TODO LP-7094: use the endpoint's schema so we have unknown -> PDPInfo
export function formatProductChoices(response: any, imageDomain: string): ProductChoiceInfo[] {
  if (!Array.isArray(response?.productChoices)) return [];

  let invalidChoices = 0;

  const formattedChoices = response.productChoices.reduce(
    // biome-ignore lint/suspicious/noExplicitAny: TODO LP-7094: use the endpoint's schema so we have unknown -> PDPInfo
    (acc: ProductChoiceInfo[], choice: any) => {
      if (typeof choice !== 'object' || choice === null) {
        console.error('formatProductChoices: Invalid choice format');
        return acc;
      }

      if (!choice.slug || !choice.title) {
        // TODO LP-5647: We should consider what action we want to take here
        console.error('formatProductChoices: Missing required fields');
        return acc;
      }

      if (!choice.mainImageUrl) invalidChoices++;

      acc.push({
        slug: choice.slug,
        title: choice.title,
        category: choice.category ?? null,
        mainImageUrl: `${imageDomain}/${choice.mainImageUrl}`,
        mainImageAltText: choice.mainImageAltText ?? deSlugify(choice.slug),
      });

      return acc;
    },
    [],
  );

  console.error(`formatProductChoices: ${invalidChoices} choices are missing mainImageUrl`);

  return formattedChoices;
}

/**
 * Formats the product URL.
 * @param response - The product response object.
 * @returns The formatted product URL.
 */
// biome-ignore lint/suspicious/noExplicitAny: TODO LP-7094: use the endpoint's schema so we have unknown -> PDPInfo
export function formatUrl(response: any): string {
  const page = response?.isCollectionProduct ? 'collection' : 'product';
  return `/${page}/${response?.slug}`;
}

/**
 * Replaces hyphens in a slug with spaces.
 *
 * @param slug - The slug to be de-slugified.
 * @returns The de-slugified string.
 */
function deSlugify(slug: string): string {
  return slug.replace(/-/g, ' ');
}
