export enum AriaLabel {
  Open = 'open',
  Close = 'close',
  OpenMenu = 'open menu',
  CloseMenu = 'close menu',
  ClosePBanner = 'close promo banner',
  LinkToBasket = 'link to basket',
  LinkToSaved = 'link to saved products',
  Search = 'search experiences',
  MegaNavMobile = 'mobile navigation menu',
}

export enum AriaRole {
  Button = 'button',
}

export enum AriaPressed {
  True = 'true',
  False = 'false',
}

export enum AriaExpanded {
  True = 'true',
  False = 'false',
}

export enum AriaControls {
  Menu = 'menu',
}

export enum AriaDescribedBy {
  Menu = 'menu',
}

export enum AriaHasPopup {
  True = 'true',
}

export enum AriaLabelledBy {
  Menu = 'menu',
}

export enum AriaCurrent {
  True = 'true',
}

export enum AriaHidden {
  True = 'true',
}
