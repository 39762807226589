import { useHeaderContext } from '../../context/HeaderContext';
import { useTheme } from '../../context/ThemeContext';
import { useLayoutStore } from '../../store';
import { cn } from '../../utils';

import HeaderButtonGroup, { type HeaderButtonGroupProps } from './ButtonGroup';
import { HeaderLogo } from './HeaderLogo';

type Props = Pick<HeaderButtonGroupProps, 'links' | 'myVoucherLink' | 'onSearchClick'> & {
  isImmersive?: boolean;
  isImmersiveHidden?: boolean;
  isSticky?: boolean;
  onLogoClick: () => void;
};

export const HeaderTop = ({
  isImmersive,
  isImmersiveHidden,
  isSticky,
  links,
  myVoucherLink,
  onLogoClick,
  onSearchClick,
}: Props) => {
  const { hideBasketCount, basketCount, saveForLaterCount } = useHeaderContext();
  const { immersive } = useTheme();
  const isMobileNavOpen = useLayoutStore((state) => state.isMobileNavOpen);

  return (
    <div className={cn(styles.container)}>
      <HeaderLogo
        className={cn(isImmersiveHidden && !isMobileNavOpen && 'hidden lg:block')}
        href="/"
        isHidden={isSticky}
        isWhite={immersive && !isMobileNavOpen}
        onClick={onLogoClick}
      />

      <HeaderButtonGroup
        basketCount={basketCount}
        hasSearchButton={!isSticky && !isImmersive && !isMobileNavOpen}
        hasWideSearch={isSticky}
        hideBasketCount={hideBasketCount}
        isImmersive={isImmersive && !isMobileNavOpen}
        links={links}
        myVoucherLink={myVoucherLink}
        onSearchClick={onSearchClick}
        saveForLaterCount={saveForLaterCount}
      />
    </div>
  );
};

const styles = {
  container: 'relative flex items-center justify-between w-full h-full z-50 lg:gap-x-2 lg:gap-y-4',
};
