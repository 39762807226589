import { useHeaderContext } from '../../context/HeaderContext';
import { generateRoute } from '../../utils/generateRoute';

import type { AnchorHTMLAttributes } from 'react';

export type NextLinkProps = {
  replace?: boolean;
  scroll?: boolean;
  prefetch?: boolean;
};

export type LinkComponentProps = {
  asSpan?: boolean;
  isExternal?: boolean;
} & NextLinkProps &
  AnchorHTMLAttributes<HTMLAnchorElement>;

const Link = ({
  href,
  children,
  replace,
  scroll,
  prefetch = false,
  asSpan = false,
  isExternal = false,
  ...rest
}: LinkComponentProps) => {
  const { NextLink, homeLink } = useHeaderContext();

  if (typeof href !== 'string') {
    console.error('Link href must be a string');
    return null;
  }

  if (asSpan) {
    return <span {...rest}>{children}</span>;
  }

  // ? if the link is external, render a normal anchor tag
  if (!NextLink || href.startsWith('http') || isExternal) {
    return (
      <a href={generateRoute(href, { homeLink })} {...rest}>
        {children}
      </a>
    );
  }

  return (
    <NextLink
      href={generateRoute(href, { homeLink, isNextLink: true })}
      replace={replace}
      scroll={scroll}
      prefetch={prefetch}
      {...rest}
    >
      {children}
    </NextLink>
  );
};

export default Link;
