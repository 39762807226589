import { useMemo } from 'react';

import { tailwindBreakpoints } from '../utils/tailwindBreakpoints';

import { useViewportSize } from './useViewportSize';

export const useIsMobileView = () => {
  const viewportWidth = useViewportSize();

  return useMemo(
    () => viewportWidth !== 0 && viewportWidth < tailwindBreakpoints.lg,
    [viewportWidth]
  );
};
