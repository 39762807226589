import VariationLayout from './SlimHeaderVariationLayout';
import DefaultLayout from './VariationLayout';
import { envVariables } from './envVariables';

import type { ProductMapType } from '@virginexperiencedays/products';
import type { ReactNode } from 'react';

export type LayoutProps = {
  children: ReactNode;
  preview?: boolean | null;
  topProducts: ProductMapType;
  initialNavItems?: unknown;
  trackingPageType?: string;
  isImmersive?: boolean;
};

const Layout = ({ children, ...props }: LayoutProps) => {
  if (envVariables.isSlimHeader) {
    return <VariationLayout {...props}>{children}</VariationLayout>;
  }
  return <DefaultLayout {...props}>{children}</DefaultLayout>;
};

export default Layout;
