import type { Price, Product } from '@virginexperiencedays/products';

export enum KlaviyoEvent {
  AddedToCart = 'Added to Cart',
  RemovedFromCart = 'Removed from Cart',
  ViewedPage = 'Viewed Page',
  ViewedProduct = 'Viewed Product',
}

// ----------------------------------------

export type KlaviyoViewedPageProps = {
  url: string;
  utm: Record<string, string>;
  title: string;
};

export type KlaviyoProductProps = Pick<
  Product,
  'title' | 'slug' | 'sku' | 'isCollectionProduct' | 'categories'
> &
  Pick<Price, 'rrp' | 'displayPrice'> & {
    imageUrl: Product['media']['mainImage']['url'];
    url: string;
  };

export type KlaviyoViewedItemProps = KlaviyoProductProps & {
  type?: 'product' | 'collection';
};

export type KlaviyoCartItemProps = KlaviyoProductProps & {
  qty: number;
  rowTotal: number;
};

export type KlaviyoCartProps = KlaviyoProductProps & {
  totalAmount: number;
  qty: number;
  checkoutUrl?: string;
  items: KlaviyoCartItemProps[];
};

// ----------------------------------------

export type KlaviyoViewedPage = {
  Url: string;
  Utm: Record<string, string>;
  PageTitle: string;
};

export type KlaviyoProduct = {
  ProductName: string;
  ProductID: string;
  SKU: string;
  Categories: string[];
  ImageURL: string;
  Url: string;
  Price: number;
  CompareAtPrice: number;
  IsCollectionProduct: boolean;
};

export type KlaviyoViewedItem = Pick<
  KlaviyoProduct,
  'SKU' | 'Categories' | 'Url' | 'IsCollectionProduct'
> & {
  Title: KlaviyoProduct['ProductName'];
  ItemId: KlaviyoProduct['ProductID'];
  ImageUrl: KlaviyoProduct['ImageURL'];
  Type: string;
  Metadata: {
    Price: KlaviyoProduct['Price'];
    CompareAtPrice: KlaviyoProduct['CompareAtPrice'];
  };
};

export type KlaviyoCartItem = Pick<
  KlaviyoProduct,
  'ProductName' | 'ProductID' | 'SKU' | 'ImageURL' | 'IsCollectionProduct'
> & {
  ProductURL: KlaviyoProduct['Url'];
  ItemPrice: KlaviyoProduct['Price'];
  ItemCompareAtPrice: KlaviyoProduct['CompareAtPrice'];
  ProductCategories: KlaviyoProduct['Categories'];
  Quantity: number;
  RowTotal: number;
};

export type KlaviyoAddedToCart = {
  $value: number;
  AddedItemProductName: KlaviyoProduct['ProductName'];
  AddedItemProductID: KlaviyoProduct['ProductID'];
  AddedItemSKU: KlaviyoProduct['SKU'];
  AddedItemCategories: KlaviyoProduct['Categories'];
  AddedItemImageURL: KlaviyoProduct['ImageURL'];
  AddedItemURL: KlaviyoProduct['Url'];
  AddedItemPrice: KlaviyoProduct['Price'];
  AddedItemCompareAtPrice: KlaviyoProduct['CompareAtPrice'];
  AddedItemQuantity: number;
  AddedItemIsCollectionProduct: KlaviyoProduct['IsCollectionProduct'];
  ItemNames: KlaviyoProduct['ProductName'][];
  CheckoutURL: string;
  Items: KlaviyoCartItem[];
};

export type KlaviyoRemovedFromCart = Pick<
  KlaviyoAddedToCart,
  '$value' | 'ItemNames' | 'CheckoutURL' | 'Items'
> & {
  RemovedItemProductName: KlaviyoAddedToCart['AddedItemProductName'];
  RemovedItemProductID: KlaviyoAddedToCart['AddedItemProductID'];
  RemovedItemSKU: KlaviyoAddedToCart['AddedItemSKU'];
  RemovedItemCategories: KlaviyoAddedToCart['AddedItemCategories'];
  RemovedItemImageURL: KlaviyoAddedToCart['AddedItemImageURL'];
  RemovedItemURL: KlaviyoAddedToCart['AddedItemURL'];
  RemovedItemPrice: KlaviyoAddedToCart['AddedItemPrice'];
  RemovedItemCompareAtPrice: KlaviyoAddedToCart['AddedItemCompareAtPrice'];
  RemovedItemQuantity: KlaviyoAddedToCart['AddedItemQuantity'];
  RemovedItemIsCollectionProduct: KlaviyoAddedToCart['AddedItemIsCollectionProduct'];
};
