import { useRef } from 'react';

import { useHeaderContext } from '../../../context/HeaderContext';
import { useClickOutside } from '../../../hooks';
import { cn } from '../../../utils';

import SearchControlsWrapper from './SearchControlsWrapper';
import SearchResultWrapper from './SearchResultsWrapper';

type SearchOverlayProps = {
  logoSrc: { red: string; white: string };
  handleLinkClicks: () => void;
};

export const SearchOverlay = ({ logoSrc, handleLinkClicks }: SearchOverlayProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { handleResetSearchState } = useHeaderContext();

  useClickOutside(ref, handleResetSearchState);

  return (
    <div ref={ref} className="relative flex-grow">
      <div
        className={cn(
          'fixed top-0 z-[100] flex w-full flex-col items-center overflow-hidden bg-background-page',
          'max-lg:inset-0 lg:shadow-sm dark:lg:shadow-[0_1px_2px_0_rgb(148_148_148_/_0.1)]'
        )}
      >
        <div
          className={cn(style.searchOverlayContainer._, style.searchOverlayContainer.largeTabletUp)}
        >
          <SearchControlsWrapper logoSrc={logoSrc} handleLinkClicks={handleLinkClicks} />
        </div>
        <div
          className={cn(style.searchOverlayContainer._, style.searchOverlayContainer.largeTabletUp)}
        >
          <SearchResultWrapper handleLinkClicks={handleLinkClicks} />
        </div>
      </div>
    </div>
  );
};

export default SearchOverlay;

const style = {
  searchOverlayContainer: {
    _: 'w-full px-4 md:px-10 xl:px-4 xl:max-w-[1312px] xl:mx-auto overflow-hidden first-of-type:pt-4 first-of-type:pb-6 first-of-type:shrink-0 first-of-type:lg:pt-6',
    largeTabletUp: 'lg:pb-6 lg:pt-0 lg:overflow-hidden',
  },
};
