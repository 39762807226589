import { ErrorCodes, RichError } from './rich-error';

type RetryOpts = {
  maxTries?: number;
  retryDelay?: number;
  maxDelay?: number;
  retryStatuses?: number[];
};

export default function retry(fn: () => Promise<Response>, opts?: RetryOpts): Promise<Response> {
  const {
    maxTries = 5,
    retryDelay = 400,
    maxDelay = Number.POSITIVE_INFINITY,
    retryStatuses,
  } = opts ?? {};

  // build sets out of error code lists, for constant-time checking
  const retryStatusesSet = new Set(retryStatuses);

  let tries = 1;

  return new Promise((resolve, reject) => {
    // set up a recursive function to keep trying
    const doTry = async (): Promise<void> => {
      try {
        const response = await fn();

        // if success, resolve immediately
        if (response.ok) return resolve(response);

        // if not, check whether we should retry
        const shouldRetry = retryStatusesSet.has(response.status);

        // retry within limit
        if (shouldRetry && tries++ <= maxTries) {
          // compute delay with exponential backoff
          // NOTE: compute in deci-seconds (400ms is 4ds), so the values don't grow too fast!
          const retryDelayInDs = retryDelay / 100;
          const backoffDelayInDs = retryDelayInDs ** tries;
          const backoffDelay = Math.min(backoffDelayInDs * 100, maxDelay);

          /**
           * Add random jitter +-50% exclusive, to avoid thundering herd.
           *
           * NOTE: Math.random() returns [0, 1), so we have to be careful in
           * manipulating the random intervals. Doing it naively like
           *
           * const jitterFactor = Math.random() * 0.5 + 1;
           *
           * looks like it would create a random factor ranging from 0.5 to 1.5
           * centered at 1, but it will actually output [0.5, 1.5) instead --
           * choosing preferentially *away* from 1.5.
           *
           * We choose instead to create two random intervals -- [-0.5, 0) and
           * (0, 0.5] -- then uniformly pick between the two of them and add to
           * 1. This is what jitterSign is for: we generate only the first
           * interval with jitterFactor, then we can flip the sign (multiply by
           * -1) to get the second interval.
           *
           * The result will thus uniformly randomize from [0.5, 1.5], EXCEPT it
           * can't land on 1.
           *
           * We've chosen to go this way over the alternative: (-0.5, 0] and
           * [0, 0.5) which double counts 0! The resulting *non-uniform* random
           * interval would've been (0.5, 1.5), choosing 1 preferentially: the
           * delays would be slightly more likely to choose an unjittered value,
           * making us still susceptible (albeit very slightly) to thundering
           * herds!
           */
          const baseJitter = Math.random() * 0.5 - 0.5; // [-0.5, 0)
          const jitterSign = Math.random() < 0.5 ? -1 : 1;
          const jitterFactor = baseJitter * jitterSign + 1;

          // recurse with the computed backoff with jitter
          setTimeout(doTry, jitterFactor * backoffDelay);
          return;
        }

        // if we're not eligible for retry, reject
        const error = new RichError({
          errorCode: ErrorCodes.ENDPOINT_UNRETRYABLE_ERROR,
          message: shouldRetry
            ? 'Retryable fetch request failed, but max retries have been exceeded.'
            : 'Fetch request failed.',
          metadata: {
            status: response.status,
            statusText: response.statusText,
            errorBody: await response.json().catch(() => null),
            maxTries,
          },
        });
        reject(error);
      } catch (error) {
        // if fn itself throws (e.g. network error), reject that as well
        reject(error);
      }
    };
    // call doTry once, to begin recursing
    doTry();
  });
}
