import { cn } from '../../../../utils';
import RenderIcon from '../../icons/RenderIcon';

import type { ButtonHTMLAttributes } from 'react';

type Props = {
  isDark?: boolean;
  placeholder?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const SearchBarButton = ({ className, isDark, placeholder, ...props }: Props) => (
  <button
    {...props}
    className={cn(
      'nowrap space-between relative dark:text-neutral-faded-high-contrast',
      'persistent-border-neutral flex h-12 flex-row items-center gap-2 overflow-hidden rounded-3xl border-2 border-solid',
      'bg-transparent px-4 text-grey-500 lg:h-10 lg:leading-none',
      'transition-[color,background-color] duration-150 ease-out',
      isDark && style.button.isDark,
      className,
    )}
  >
    <RenderIcon
      fill={isDark ? 'var(--persistent-white)' : 'var(--text-neutral-strong)'}
      name="search"
    />
    <span className="relative z-10 flex-1 text-left text-base leading-none lg:text-sm lg:leading-none">
      {placeholder}
    </span>
  </button>
);

export default SearchBarButton;

const style = {
  button: {
    isDark:
      'text-white [background:linear-gradient(0deg,rgba(0,0,0,0.2),rgba(0,0,0,0.2)),rgba(0,0,0,0.5)]',
  },
};
