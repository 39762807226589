import { RefObject, useEffect, useRef, useState } from 'react';

type Props = {
  elementRef: RefObject<Element | undefined>;
  options?: IntersectionObserverInit;
  callbackFn?: (intersecting: boolean) => void;
  disabled?: boolean;
};

const useInView = ({
  elementRef,
  options = { threshold: 0, root: null, rootMargin: '0%' },
  callbackFn,
  disabled,
}: Props) => {
  const refObserver = useRef<IntersectionObserver | null>(null);

  const [isIntersecting, setIsIntersecting] = useState(false);

  const callback = (entries) => {
    const isIntersecting = !!entries?.[0]?.isIntersecting;
    setIsIntersecting(isIntersecting);
    callbackFn?.(isIntersecting);
  };

  useEffect(() => {
    if (disabled || !window?.IntersectionObserver || !elementRef?.current || refObserver.current)
      return;

    refObserver.current?.disconnect();

    refObserver.current = new IntersectionObserver(callback, options);
    refObserver.current.observe(elementRef.current);

    return () => {
      refObserver.current?.disconnect();
    };
  }, [disabled, options.root, options.rootMargin, options.threshold]);

  return isIntersecting;
};

export { useInView };
