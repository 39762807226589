import { cn, ctaLinkToDataLayer } from '../../utils';
import ButtonLink from '../common/ui/buttons/ButtonLink';

type Props = {
  href?: string | null;
  isLight?: boolean;
};

export const HeaderVoucherLink = ({ href, isLight }: Props) => {
  if (!href?.length) return null;

  return (
    <ButtonLink
      href={href}
      onClick={() => ctaLinkToDataLayer('Got a voucher?', href)}
      className={cn(
        'text-md hidden py-2 lg:flex lg:h-10',
        isLight && 'border-persistent-border-disabled bg-transparent text-persistent-text-light'
      )}
    >
      Got a voucher?
    </ButtonLink>
  );
};
