import algoliasearch from 'algoliasearch';

import { useHeaderContext } from '../context/HeaderContext';

const useAlgoliasearch = () => {
  const { algoliaAppId, algoliaSearchApiKey, algoliaSearchIndex } = useHeaderContext();

  const searchClient = algoliasearch(algoliaAppId, algoliaSearchApiKey);

  const siteSearch = async (searchTerm: string, initialRoute: string) => {
    const { results } = await searchClient.search([
      {
        indexName: algoliaSearchIndex,
        query: searchTerm,
        params: {
          hitsPerPage: 1,
        },
      },
    ]);
    const redirect: any = results.pop();
    const redirectUrl =
      redirect?.renderingContent?.['redirect']?.url ?? redirect?.userData?.[0]?.redirect?.url;
    if (redirectUrl) {
      window.location.href = redirectUrl;
    } else if (searchTerm) {
      window.location.href = `${initialRoute}?query=${searchTerm}`;
    } else {
      window.location.href = initialRoute;
    }
  };
  return { siteSearch };
};

export default useAlgoliasearch;
