import { type HTMLAttributes, forwardRef } from 'react';

import { cn } from '../../utils';

type Props = {
  isVisible?: boolean;
} & HTMLAttributes<HTMLDivElement>;

export const BlurScreen = forwardRef<HTMLDivElement, Props>(
  ({ className, isVisible, ...props }, ref) => (
    <div
      ref={ref}
      {...props}
      className={cn(
        'fixed inset-0 top-0 backdrop-blur-sm opacity-0 invisible transition-[opacity,visibility] ease-out duration-[0s,0s]',
        'lg:duration-[0.05s,0s] lg:delay-[0s,0.05s]',
        className,
        isVisible &&
          'opacity-100 visible duration-[0.25s,0s] lg:duration-[0.075s,0s] lg:delay-[0s,0s]',
      )}
    >
      <div className="absolute inset-0 bg-gray-800/20" />
    </div>
  ),
);
