type FlagsMap = Record<string, number>;

/*
- all flags need to be listed here in order for
  next to allow use them in browser with `NEXT_PUBLIC_` prefix.
- `_FF_` is used to distinguish flags from other variables
- here we can setup default variables for flags
*/

const flags: FlagsMap = {
  FF_test: Number.parseInt(process.env.NEXT_PUBLIC_FF_TEST || '0') || 0,
  FF_new_filters: Number.parseInt(process.env.NEXT_PUBLIC_FF_NEW_FILTERS || '0') || 0,
  FF_fonts: Number.parseInt(process.env.NEXT_PUBLIC_FF_FONTS || '1') || 1,
  FF_gtm: Number.parseInt(process.env.NEXT_PUBLIC_FF_GTM || '0') || 0,
  FF_fb_capi: Number.parseInt(process.env.NEXT_PUBLIC_FF_FB_CAPI || '0') || 0,
  FF_fb_pixel: Number.parseInt(process.env.NEXT_PUBLIC_FF_FB_PIXEL || '0') || 0,
  FF_klaviyo: Number.parseInt(process.env.NEXT_PUBLIC_FF_KLAVIYO || '0') || 0,
  FF_sentry: Number.parseInt(process.env.NEXT_PUBLIC_SENTRY || '0') || 0,
  FF_limited_deployments: Number.parseInt(process.env.FF_LIMITED_DEPLOYMENTS || '0') || 0,
  FF_ab_tests: Number.parseInt(process.env.FF_AB_TESTS || '0') || 0,
  FF_header_2024: Number.parseInt(process.env.NEXT_PUBLIC_FF_HEADER_2024 || '0') || 0,
  FF_product_cards_v2: Number.parseInt(process.env.NEXT_PUBLIC_FF_PRODUCT_CARDS_V2 || '0') || 0,
  FF_cms_cache: Number.parseInt(process.env.NEXT_PUBLIC_FF_CMS_CACHE || '1') || 1,
  FF_featured_column_fallback:
    Number.parseInt(process.env.NEXT_PUBLIC_FF_NAV_FEATURED_COLUMN_FALLBACK || '0') || 0,
  FF_new_product_api: Number.parseInt(process.env.FF_NEW_PRODUCT_API || '0') || 0,
  FF_loyalty: Number.parseInt(process.env.NEXT_PUBLIC_FF_LOYALTY || '0') || 0,
  FF_new_pdp: Number.parseInt(process.env.NEXT_PUBLIC_FF_NEW_PDP || '0') || 0,
  FF_new_pdp_collections:
    Number.parseInt(process.env.NEXT_PUBLIC_FF_NEW_PDP_COLLECTIONS || '0') || 0,
  FF_new_bundles_map: Number.parseInt(process.env.NEXT_PUBLIC_FF_NEW_BUNDLES_MAP || '0') || 0,
  FF_new_pdp_video: Number.parseInt(process.env.NEXT_PUBLIC_FF_NEW_PDP_VIDEO || '0') || 0,
  FF_slim_header: Number.parseInt(process.env.NEXT_PUBLIC_FF_SLIM_HEADER || '0') || 0,
};

export default flags;
