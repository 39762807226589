import { useHeaderContext } from '../../../context/HeaderContext';
import { cn, createAbsoluteUrl } from '../../../utils';

import Image from './Image';

type Props = {
  enforceRed?: boolean;
  isWhite?: boolean;
  src: string | { red: string; white: string };
};

const IMAGE_PROPS = {
  alt: 'Virgin Experience Days Logo',
  width: 100,
  height: 40,
  loading: 'eager' as const,
};

const Logo = ({ enforceRed, isWhite, src }: Props) => {
  const { homeLink } = useHeaderContext();

  if (!src) throw new Error('VED logo is required');
  if (typeof src !== 'string' && !src.red) throw new Error('Red VED logo is required');

  const variants = typeof src === 'string' ? { red: src } : src;
  const shouldBeWhite = !enforceRed && isWhite;

  return (
    <div className="relative w-full">
      <Image
        className={cn('duration-150 transition-opacity ease-out', shouldBeWhite && 'opacity-0')}
        src={createAbsoluteUrl(homeLink, variants.red)}
        {...IMAGE_PROPS}
      />

      {'white' in variants && variants.white && (
        <Image
          className={cn(
            'duration-150 absolute left-0 top-0 opacity-0 transition-opacity ease-out',
            shouldBeWhite && 'opacity-100',
          )}
          src={createAbsoluteUrl(homeLink, variants.white)}
          {...IMAGE_PROPS}
        />
      )}
    </div>
  );
};

export default Logo;
