import type { FormatterContext } from '../endpoint';
import type { PaginatedResult, ProductCard } from '../types';
import { ErrorCodes, RichError } from '../utils/rich-error';
import { formatMediaInfo, formatPrice, formatReviewsInfo } from './formatPDPInfo';

/**
 * @description TODO
 * @throws {RichError} ErrorCodes.INVALID_RESPONSE_FORMAT
 */
// biome-ignore lint/suspicious/noExplicitAny: TODO LP-7094: use the endpoint's schema so we have unknown -> ProductCard
function formatProductCard(item: any, ctx: FormatterContext): ProductCard | null {
  if (typeof item !== 'object' || item === null) {
    throw new RichError({
      errorCode: ErrorCodes.INVALID_RESPONSE_FORMAT,
      message: 'ProductCard item is not an object.',
      metadata: { item },
    });
  }

  const { mediaCdnBaseUrl } = ctx;

  return {
    categories: Array.isArray(item?.categories) ? item.categories : [],
    categoryHierarchy: item.categoryHierarchy ?? null,
    dateActivated: item.dateActivated ?? null,
    facets: item.facets,
    id: item.id,
    isCollectionProduct: !!item.isCollectionProduct,
    isExclusive: !!item.isExclusive,
    locations: Array.isArray(item?.locations) ? item.locations : [],
    media: formatMediaInfo(item, mediaCdnBaseUrl),
    price: formatPrice(item),
    promocode: item.promocode,
    reviews: formatReviewsInfo(item),
    searchIndex: item?.searchindex ?? null,
    sku: item.sku,
    slug: item.slug,
    status: item.status,
    suppliers: Array.isArray(item?.suppliers) ? item.suppliers : [],
    title: item.title,
    url: item.url,
  };
}

export function formatProductCards(
  response: PaginatedResult<unknown>,
  ctx: FormatterContext,
): PaginatedResult<ProductCard> {
  if (typeof response !== 'object' || response === null) {
    return { total: 0, items: [] };
  }

  const formattedItems = response.items.reduce<ProductCard[]>((acc, item) => {
    const formattedItem = formatProductCard(item, ctx);
    if (formattedItem !== null) {
      acc.push(formattedItem);
    }
    return acc;
  }, []);
  return { ...response, items: formattedItems };
}
