import { TestId } from '../../../../enums';
import { cn, ctaLinkToDataLayer, navLinkToDataLayer } from '../../../../utils';
import RenderIcon from '../../../common/icons/RenderIcon';
import PromoRibbon from '../../../common/ui/PromoRibbon';
import ButtonLink from '../../../common/ui/buttons/ButtonLink';

import BackButtonNav from './BackButtonNav';
import IconNavList from './IconNavList';
import ImageNavList from './ImageNavList';
import MenuItemButton from './MenuItemButton';

import { useTrackingStore } from '../../../../store';

import type { Dispatch, SetStateAction } from 'react';
import type {
  CtaType,
  DecoratorType,
  HeaderProps,
  LevelDecoratorsType,
  NavigationItem,
} from '../../../../types';

export type NavStackState = {
  currentLvl: number;
  selectedStack: string[];
};

export type NavListComponentProps = {
  activeNavStack: NavStackState;
  cta?: CtaType;
  decorators?: LevelDecoratorsType;
  navItems?: HeaderProps['navigation']['navItems'];
  navLvl: number;
  parentNameList?: string[];
  shopAll?: boolean;
  viewAllRoute?: string;
  setActiveNavStack: Dispatch<SetStateAction<NavStackState>>;
  handleCloseAndResetNav: () => void;
  handleRestoreScroll?: () => void;
};

const NavList = ({
  activeNavStack,
  cta,
  decorators,
  navItems,
  navLvl,
  parentNameList = [],
  shopAll,
  viewAllRoute,
  setActiveNavStack,
  handleRestoreScroll,
  handleCloseAndResetNav,
}: NavListComponentProps) => {
  const headerState = useTrackingStore((state) => state.headerState);
  if (!Array.isArray(navItems)) return null;

  const decoratorLvlMap: Map<number, DecoratorType | null> = new Map([
    [1, decorators?.lvl1Decorator ?? null],
    [2, decorators?.lvl2Decorator ?? null],
    [3, decorators?.lvl3Decorator ?? null],
  ]);

  const currentLvlDecorator: DecoratorType | null = decoratorLvlMap.get(navLvl) ?? null;

  const navItemsWithChildren: NavigationItem[] = navItems.filter(
    (item) => Array.isArray(item?.children) && !!item.children.length,
  );
  const navItemsWithoutChildren: NavigationItem[] = navItems.filter(
    (item) => !Array.isArray(item?.children) || !item?.children?.length,
  );

  // organize nav items with children first on levels 2 and above only
  const organizedNavItems =
    navLvl === 1 ? navItems : [...navItemsWithChildren, ...navItemsWithoutChildren];

  const navItemsWithDecorator = (decorator: DecoratorType) =>
    organizedNavItems.filter((item) => item[decorator]);

  const navItemsWithoutDecorator = (decorator: DecoratorType) =>
    organizedNavItems.filter((item) => item[decorator] === undefined);

  const defaultNavItems = currentLvlDecorator
    ? navItemsWithoutDecorator(currentLvlDecorator)
    : organizedNavItems;

  const currentParent = activeNavStack.selectedStack[activeNavStack.selectedStack.length - 1];

  const handleBackNav = () => {
    const newSelectedStack = [...activeNavStack.selectedStack];
    newSelectedStack.pop();
    setActiveNavStack({
      currentLvl: activeNavStack.currentLvl - 1,
      selectedStack: newSelectedStack,
    });
  };

  const isActiveNavItems = parentNameList.every(
    (value, index) => value === activeNavStack.selectedStack[index],
  );

  const sharedListProps = {
    activeNavStack,
    cta,
    currentParent,
    decorators,
    isActiveNavItems,
    navLvl,
    parentNameList,
    setActiveNavStack,
    handleRestoreScroll,
    handleCloseAndResetNav,
  } as const;

  const handleClick = (isLink: boolean, name: string) => {
    if (isLink) {
      handleCloseAndResetNav();
      navLinkToDataLayer({ path: [...parentNameList, name], value: name, headerState });
    } else {
      setActiveNavStack({
        currentLvl: activeNavStack.currentLvl + 1,
        selectedStack: [...activeNavStack.selectedStack, name],
      });
    }
    handleRestoreScroll?.();
  };

  const handleTrackCtaAndReset = (value: string, route: string) => {
    ctaLinkToDataLayer(value, route);
    handleCloseAndResetNav();
  };

  const handleClickViewAll = () => {
    navLinkToDataLayer({ path: [...parentNameList, 'View All'], value: 'View All', headerState });
    handleCloseAndResetNav();
  };

  return (
    <>
      {currentParent && (
        <BackButtonNav
          handleClickViewAll={handleClickViewAll}
          className={cn({
            'sr-only': navLvl !== activeNavStack.currentLvl || (currentParent && !isActiveNavItems),
          })}
          parentName={currentParent}
          onClick={handleBackNav}
          viewAllRoute={viewAllRoute}
        />
      )}
      <ul data-testid={TestId.PrimaryNavList}>
        {currentLvlDecorator === 'icon' && !!navItemsWithDecorator('icon')?.length && (
          <li>
            <IconNavList {...sharedListProps} navItems={navItemsWithDecorator('icon')} />
          </li>
        )}

        {currentLvlDecorator === 'image' && !!navItemsWithDecorator('image')?.length && (
          <li>
            <ImageNavList {...sharedListProps} navItems={navItemsWithDecorator('image')} />
          </li>
        )}

        {defaultNavItems?.map((item, index) => {
          const name = item.nameSEO ?? item.name;
          const mobileCta = item?.mobile?.cta;
          const ribbon = item?.mobile?.ribbon;
          const decoratorsLvls = item?.mobile?.decorators;

          const hasChildren = !!item?.children?.length;
          const isLink = !hasChildren && !!item?.route;

          const href = isLink ? item.route : undefined;

          return (
            <li key={`navigation-${navLvl}-${item.name}-${item.route}-${index}`}>
              <MenuItemButton
                data-level={navLvl}
                href={href}
                className={cn(
                  'flex items-center justify-between py-2.5 text-neutral hover:text-neutral',
                  {
                    'sr-only py-0':
                      navLvl !== activeNavStack.currentLvl || (currentParent && !isActiveNavItems),
                  },
                )}
                onClick={() => handleClick(isLink, name)}
              >
                <span
                  className={cn({
                    [style.nameStyleLvl.lvl1]: navLvl === 1,
                    [style.nameStyleLvl.lvl2]: navLvl > 1,
                  })}
                >
                  {!!item?.icon && (
                    <RenderIcon
                      // show nav icon only on Level 1
                      className={cn(navLvl > 1 && 'hidden')}
                      name={item.icon}
                      themeColor="var(--text-neutral)"
                      width={20}
                      height={20}
                    />
                  )}
                  {name}
                </span>
                {hasChildren && (
                  <RenderIcon
                    name="chevron-right"
                    themeColor="var(--text-neutral)"
                    width={14}
                    height={14}
                  />
                )}
                {!!ribbon && (
                  <PromoRibbon type={ribbon.type} device="mobile">
                    {ribbon.text}
                  </PromoRibbon>
                )}
              </MenuItemButton>

              {hasChildren && (
                <NavList
                  {...sharedListProps}
                  cta={mobileCta ?? cta}
                  decorators={decoratorsLvls ?? decorators}
                  navItems={item.children}
                  navLvl={navLvl + 1}
                  parentNameList={[...parentNameList, name]}
                  shopAll={item?.shopAll}
                  viewAllRoute={item.route}
                  handleCloseAndResetNav={handleCloseAndResetNav}
                />
              )}
            </li>
          );
        })}
      </ul>
      {cta && navLvl === 2 && (
        <ButtonLink
          href={cta?.route}
          onClick={() => handleTrackCtaAndReset(cta.text, cta.route)}
          isPrimary
          className={cn('mt-6', {
            'sr-only m-0 p-0':
              navLvl !== activeNavStack.currentLvl || (currentParent && !isActiveNavItems),
          })}
        >
          {cta.text}
        </ButtonLink>
      )}
      {shopAll && navLvl === 3 && (
        <ButtonLink
          href={viewAllRoute}
          onClick={handleClickViewAll}
          isPrimary
          className={cn('mt-6', {
            'sr-only m-0 p-0':
              navLvl !== activeNavStack.currentLvl || (currentParent && !isActiveNavItems),
          })}
        >
          Shop All {currentParent?.replace(/^[aA]ll /, '')}
        </ButtonLink>
      )}
    </>
  );
};

export default NavList;

const style = {
  nameStyleLvl: {
    lvl1: 'text-lg truncate font-semibold flex items-center gap-3',
    lvl2: 'text-lg truncate font-medium',
  },
};
