import retry from './utils/retry';
import { ErrorCodes, RichError, type RichErrorOpts, handleRichError } from './utils/rich-error';

export type EndpointOptions<T> = {
  baseUrl: string;
  key?: string;
  mediaCdnBaseUrl: string;
  // biome-ignore lint/suspicious/noExplicitAny: TODO LP-7094: use the endpoint's schema so we have unknown -> T
  formatter?: (response: any, context: FormatterContext) => T;
};

/**
 * Formats a query object into the string counterpart.
 *
 * @example
 * const result = formatQuery({
 *   str: 'foo',
 *   num: 1,
 *   bool: true,
 *   arr: ['foo', 'bar', 'baz'],
 * });
 * console.log(result);
 * // '?str=foo&num=1&bool=true&arr=foo&arr=bar&arr=baz'
 */
const formatQuery = (query: Record<string, unknown>): string => {
  if (!query) return '';

  const entries = Object.entries(query).reduce(
    (acc, [key, value]) => {
      if (value === undefined || value === null) {
        return acc; // skip undefined or null values
      }

      if (Array.isArray(value)) {
        return acc.concat(value.map((v) => [key, v]));
      }

      acc.push([key, String(value)]); // Ensure the value is a string
      return acc;
    },
    [] as [string, string][],
  );

  return new URLSearchParams(entries).toString();
};

export type FormatterContext = {
  mediaCdnBaseUrl: string;
};

/**
 * Represents an endpoint that can be used to find data based on a query.
 *
 * This is a client endpoint that is responsible for talking directly to a
 * single endpoint. The API client, in general, is a collection of Endpoints.
 *
 * @template Q The type of the query.
 * @template T The type of the data to be returned.
 */
export interface Endpoint<Q, T> {
  /**
   * Finds data based on the provided query.
   *
   * @param query The query used to find the data.
   * @returns A promise that resolves to the found data.
   * @throws Will throw an error if the fetch is unsuccessful.
   */
  find(query: Q): Promise<T>;
  get(slug: string): Promise<T>;
}

export class FetchEndpoint<Q, T> implements Endpoint<Q, T> {
  private readonly baseUrl: string;
  private readonly endpoint: string;
  private readonly key: string;
  private readonly mediaCdnBaseUrl: string;
  private readonly formatter?: EndpointOptions<T>['formatter'];

  constructor(endpoint: string, opts: EndpointOptions<T>) {
    const { baseUrl, key = '', mediaCdnBaseUrl, formatter } = opts ?? {};

    // validation
    if (!endpoint) {
      throw new Error(
        handleRichError(
          ErrorCodes.ENDPOINT_MISSING_ENDPOINT,
          new Error('Endpoint name is required'),
        ),
      );
    }
    if (!baseUrl) {
      throw new Error(
        handleRichError(
          ErrorCodes.ENDPOINT_MISSING_BASE_URL,
          new Error('Endpoint base URL is required'),
        ),
      );
    }

    this.baseUrl = baseUrl;
    this.endpoint = endpoint;
    this.key = key;
    this.mediaCdnBaseUrl = mediaCdnBaseUrl;
    this.formatter = formatter;
  }

  get url() {
    return [this.baseUrl, this.endpoint].join('/');
  }

  async find(query: Q): Promise<T> {
    const options: RequestInit = {
      method: 'GET',
      headers: {
        'X-Api-Key': this.key,
      },
    };

    const pathWithQuery = query
      ? [this.url, formatQuery(query as Record<string, unknown>)].filter(Boolean).join('?')
      : this.url;

    const response = await retry(() => fetch(pathWithQuery, options), {
      retryStatuses: [429, 503],
    });

    const responseJSON = await response.json();

    const formatterContext = { mediaCdnBaseUrl: this.mediaCdnBaseUrl };
    // TODO LP-7094: use a schema for each endpoint regardless of existence of formatter
    return this.formatter ? this.formatter(responseJSON, formatterContext) : (responseJSON as T);
  }

  async get(slug: string): Promise<T> {
    if (!slug?.trim()) {
      throw new RichError({
        errorCode: ErrorCodes.ENDPOINT_NO_SLUG,
        message: 'Endpoint slug is required',
      });
    }
    if (!/^[a-zA-Z0-9-_]+$/.test(slug)) {
      throw new RichError({
        errorCode: ErrorCodes.ENDPOINT_SLUG_INVALID_CHARACTERS,
        message:
          'Endpoint slug contains invalid characters. Only "a-z", "A-Z", "0-9", "-", "_" allowed.',
      });
    }

    const options: RequestInit = {
      method: 'GET',
      headers: {
        'X-Api-Key': this.key,
      },
    };

    const pathWithSlug = slug ? [this.url, slug].join('/') : this.url;

    const response = await retry(() => fetch(pathWithSlug, options), {
      retryStatuses: [429, 503],
    });

    if (!response.ok) {
      throw new RichError({
        errorCode: ErrorCodes.ENDPOINT_NOT_OK,
        message: 'When fetching endpoint, response was not ok',
      });
    }

    let responseJSON: T | PromiseLike<T>;

    try {
      responseJSON = await response.json();
    } catch (error) {
      const opts: RichErrorOpts = {
        errorCode: ErrorCodes.INVALID_JSON,
        message: 'Invalid JSON response',
      };
      if (error instanceof Error) opts.metadata = { originalError: error.message };
      throw new RichError(opts);
    }

    const formatterContext = { mediaCdnBaseUrl: this.mediaCdnBaseUrl };
    // TODO LP-7094: use a schema for each endpoint regardless of existence of formatter
    return this.formatter ? this.formatter(responseJSON, formatterContext) : (responseJSON as T);
  }
}

export default FetchEndpoint;
