import { type HTMLAttributes, type ReactNode, useRef } from 'react';

import { useTheme } from '../../../../context/ThemeContext';
import { useTrackingStore } from '../../../../store';
import { useMenuContext } from './MenuContext';

import { cn, navLinkToDataLayer } from '../../../../utils';
import { slugify } from '../../../../utils/slugify';
import Link from '../../../common/Link';
import RenderIcon, { type IconName } from '../../../common/icons/RenderIcon';
import PromoRibbon from '../../../common/ui/PromoRibbon';

import type { DeviceConfigType } from '../../../../types';

type Props = {
  name: string;
  icon?: IconName;
  iconClassName?: string;
  iconSize?: number;
  route?: string;
  childItems?: unknown[];
  highlight?: boolean;
  menuAlign?: 'left' | 'right';
  inOverflowMenu?: boolean;
  desktop?: Omit<DeviceConfigType, 'cta'>;
  children?: ReactNode;
  handleOverflowOpen?: () => void;
  handleOverflowClose?: () => void;
} & HTMLAttributes<HTMLElement>;

type ElementWrapProps = {
  isLink?: boolean;
  href?: string;
  children: ReactNode;
  className?: HTMLElement['className'];
  handleClick: () => void;
};

const ItemElementWrap = ({ isLink, href, children, className, handleClick }: ElementWrapProps) => {
  if (isLink) {
    return (
      <Link href={href} onClick={handleClick} className={cn('inline-block', className)}>
        {children}
      </Link>
    );
  }
  return <div className={className}>{children}</div>;
};

const Level1Item = ({
  name,
  icon,
  iconClassName,
  iconSize = 16,
  route,
  highlight,
  menuAlign = 'left',
  inOverflowMenu,
  desktop,
  children,
  className,
  handleOverflowOpen,
  handleOverflowClose,
}: Props) => {
  const hoverListRef = useRef<HTMLDivElement | null>(null);

  const { theme, immersive } = useTheme();
  const { lvl1, handleLvl1 } = useMenuContext();
  const headerState = useTrackingStore((state) => state.headerState);

  const hoverComponentId = `nav-lvl1-${slugify(name)}`;
  const isLink = !!route;

  const handleFocus = () => {
    if (children) handleOverflowOpen?.();
    handleLvl1(name, hoverListRef);
  };

  const handleBlur = () => {
    handleOverflowClose?.();
    handleLvl1(null, hoverListRef);
  };

  const interactionMethods = {
    onMouseEnter: handleFocus,
    onMouseLeave: handleBlur,
    onFocus: handleFocus,
    onBlur: handleBlur,
  } as const;

  const iconProps = {
    className: cn(
      highlight && 'text-neutral dark:text-white',
      theme === 'dark' && highlight && 'text-persistent-text-light',
      immersive && 'text-white',
      immersive && highlight && 'text-neutral',
      iconClassName,
    ),
    name: icon as IconName,
    width: iconSize,
    height: iconSize,
    device: 'desktop',
    fill: 'currentColor',
  } as const;

  const { ribbon } = desktop || {};

  const TEMP_IS_BLACK_FRIDAY = name === 'Black Friday' || name === 'Cyber Monday';

  return (
    <li
      // biome-ignore lint/a11y/noNoninteractiveTabindex: <removing tabIndex causes the modal not to close after clicking on the item>
      tabIndex={0}
      aria-controls={hoverComponentId}
      aria-label={children ? `View related categories for ${name}` : undefined}
      className={cn('group cursor-pointer rounded-sm pr-8 text-sm', className)}
      {...interactionMethods}
    >
      <ItemElementWrap
        href={route}
        handleClick={() => {
          navLinkToDataLayer({ path: [], value: name, headerState });
          handleBlur();
        }}
        isLink={isLink}
        className={cn(
          'border-b-2 border-transparent py-1 text-sm text-neutral group-hover:border-background-primary group-hover:text-neutral',
          lvl1 === name && 'border-background-primary',
          highlight && !TEMP_IS_BLACK_FRIDAY && style.highlight._,
          highlight && style.highlight.dark,
          TEMP_IS_BLACK_FRIDAY && style.highlight.TEMP_BLACK_FRIDAY,
          immersive && !inOverflowMenu && 'text-white group-hover:text-white',
          immersive && highlight && !inOverflowMenu && 'text-white',
          highlight && immersive && style.highlight.immersive,
        )}
      >
        <h2 className="nowrap flex flex-row items-center justify-center gap-1 leading-[inherit]">
          {!!icon && <RenderIcon {...iconProps} />}
          <span className={cn('whitespace-nowrap font-medium')}>{name}</span>
          {!!ribbon && (
            <PromoRibbon type={ribbon.type} device="desktop">
              {ribbon.text}
            </PromoRibbon>
          )}
        </h2>
      </ItemElementWrap>
      <div
        ref={hoverListRef}
        id={hoverComponentId}
        className={cn('sr-only absolute left-2/4 top-full -translate-x-2/4', {
          'left-auto right-0 -translate-x-0': menuAlign === 'right',
        })}
      >
        {!!children && children}
      </div>
    </li>
  );
};

export default Level1Item;

const style = {
  highlight: {
    _: 'p-1 rounded leading-none border-0 bg-background-primary-faded group-hover:bg-background-primary-faded-hover',
    dark: 'dark:bg-background-primary dark:text-white dark:group-hover:bg-background-primary-faded-hover dark:group-hover:text-white',
    immersive:
      '.bg-background-primary-faded group-hover:bg-background-primary-faded-hover group-hover:text-persistent-text-light text-neutral group-hover:text-neutral',
    TEMP_BLACK_FRIDAY:
      'p-1 rounded leading-none border-0 bg-black text-white dark:bg-white dark:text-black group-hover:text-white dark:group-hover:text-black group-hover:opacity-80',
  },
};
