import RenderIcon, { type IconName } from '../../../components/common/icons/RenderIcon';
import Link from '../../common/Link';

type SocialItem = {
  icon: IconName;
  route: string;
};

const socialItems: SocialItem[] = [
  {
    icon: 'facebook',
    route: 'https://www.facebook.com/virginexperiencedays',
  },
  {
    icon: 'twitter',
    route: 'https://twitter.com/virginexp',
  },
  {
    icon: 'instagram',
    route: 'https://instagram.com/virginexperiencedays',
  },
  {
    icon: 'youtube',
    route: 'https://www.youtube.com/c/virginexperiencedays',
  },
];

const SocialIcons = () => (
  <div className="order-1 mb-10 flex justify-center lg:order-4 lg:mb-0 lg:pl-6">
    <ul className="m-0 flex list-none pl-0">
      {socialItems.map(({ icon, route }) => (
        <li
          key={`socialIcon-${icon}-${route}`}
          className="mr-2 overflow-hidden rounded last-of-type:mr-0"
        >
          <Link
            href={route}
            target="_blank"
            className="flex h-10 w-10 items-center justify-center bg-primary-600 no-underline outline-none ring-inset ring-tones-info-500 transition-colors duration-150 ease-linear hover:bg-primary-400 focus:ring-2"
          >
            <RenderIcon width="20px" height="100%" name={icon} />
          </Link>
        </li>
      ))}
    </ul>
  </div>
);

export default SocialIcons;
