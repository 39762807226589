import { useHeaderContext } from '../../context/HeaderContext';
import { cn } from '../../utils';
import Link, { type LinkComponentProps } from '../common/Link';
import Logo from '../common/ui/Logo';

type Props = LinkComponentProps & {
  isHidden?: boolean;
  isWhite?: boolean;
};

export const HeaderLogo = ({ className, isHidden, isWhite, ...rest }: Props) => {
  const { siteLogoDefault, siteLogoWhite } = useHeaderContext();

  return (
    <Link
      href="/"
      className={cn(
        'flex-shrink-0 absolute left-0 top-0 z-[1] m-1 flex h-10 w-[100px] items-center justify-center overflow-hidden',
        'transition-[opacity,visibility] duration-[var(--header-transition-timing),0s] ease-out',
        'lg:relative lg:m-0',
        isHidden && 'invisible opacity-0 delay-[0s,var(--header-transition-timing)] lg:visible lg:opacity-100',
        className,
      )}
      {...rest}
    >
      <Logo
        src={{ red: siteLogoDefault as string, white: siteLogoWhite as string }}
        isWhite={isWhite}
      />
    </Link>
  );
};
